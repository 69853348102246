import React from 'react';

import { withStyles } from '@mui/styles';
import * as _R from 'ramda';
import * as _cs from '../common/common';
import { formatName } from '../common/common';

const styles = theme => ({
    chip: {
        height: '18px',
        fontSize: '0.7rem'
    },
    statusIndicator: {
        fontSize: '0.8rem'
    },
    statusIndicatorSuccess: {
        color: 'green',
        textTransform: 'uppercase',
        fontSize: '0.7rem',
        border: '1px solid green',
        padding: '0.1rem 0.2rem',
        borderRadius: '2px',
    },
    statusIndicatorError: {
        color: '#ed143d',
        textTransform: 'uppercase',
        fontSize: '0.7rem',
        border: '1px solid #ed143d',
        padding: '0.1rem 0.2rem',
        borderRadius: '2px',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
    },
});
const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export const UserBasic = withStyles(styles)(props => {
    var thumb_url = '';
    if (_R.type(props.Photo) !== 'Undefined' && props.Photo) {
        thumb_url = props.Photo.thumb_url;
    }
    let info = props.PersonalInfo;
    let full_name = formatName(info.first_name, null, info.last_name);
    let Profile = props.Profile;
    let JobTitle = props.JobTitle;
    return (
        <React.Fragment>
            <div class="profile-view-aside">         
                <div class="pb-image">
                    <div class="helper">
                        {(thumb_url !== '') ? <img src={thumb_url} alt="" />
                            : <img src={BASE_PATH + "/noimage.jpg"}  alt="" />
                        }
                    </div>
                </div>               
              
              <div class="pb pb-main" style={{'marginTop': '1rem'}}>
                    <div class="pbi-item pbi-name">{full_name}</div>
                    <div class="pbi-item pbi-status-group">
                        <div data-icon="mail_outline" className={'profile-view__basics-item item-email pbi-email'}>{info.user_email}</div>
                        <div data-icon="phone" className={'profile-view__basics-item item-phone pbi-phone'}>{_cs.formatPhone(info.user_mobile)}</div>
                    </div>
                    <div class="pbi-item pbi-status-group">
                        {(typeof Profile.user_status_name !== 'undefined' && Profile.user_status_name.toLowerCase() === 'active') ?
                            <div class="pbi-status">
                                <span class="status-view status-active">{(typeof Profile.user_status_name !== 'undefined' && Profile.user_status_name.toUpperCase())}</span>
                            </div>
                            : <div class="pbi-status">
                                <span class="status-view status-inactive">{(typeof Profile.user_status_name !== 'undefined' && Profile.user_status_names.toUpperCase())}</span>
                            </div>
                        }
                    </div>
                </div>
                <div class="pb pb-info">
                    <ul class="pbi-info-list">
	    	    {(Profile.staff_id !== null) &&
                    <>
			<li>
                            <span class="label">Staff ID</span>
                            <span class="value">{Profile.staff_id}</span>
                        </li>
                        {props.DisplayEmployeeCode && Profile.employee_id &&
                            <li>
                                <span class="label">Employee ID</span>
                                <span class="value">{Profile.employee_id}</span>
                            </li>
                        }
                        <li>
                            <span class="label">Joined</span>
                            <span class="value">{Profile.date_joined}</span>
                        </li>
                        {Profile.relieved_date &&
                            <li>
                                <span class="label">Relieved</span>
                                <span class="value">{Profile.relieved_date}</span>
                            </li>
                        }
                        <li>
                            <span class="label">Job Title</span>
                            <span class="value">{JobTitle ? (JobTitle) : null}</span>
                        </li>
                        {info.legal_first_name &&
                            <li>
                                <span class="label">Legal First Name</span>
                                <span class="value">{info.legal_first_name}</span>
                            </li>
                        }
                        {info.legal_middle_name &&
                            <li>
                                <span class="label">Legal Middle Name</span>
                                <span class="value">{info.legal_middle_name}</span>
                            </li>
                        }
                        {info.legal_last_name &&
                            <li>
                                <span class="label">Legal Last Name</span>
                                <span class="value">{info.legal_last_name}</span>
                            </li>
                        }
		    </>
		    }
                    </ul>
                </div>
            </div>

        </React.Fragment>
    )
});
