import React from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { SSO_AUTH_URL,GOOGLE_AUTH_URL,MICROSOFT_AUTH_URL } from '../../constants';
import { CompanyInfo } from './companyinfo.component'
import { styled } from '@mui/material/styles';

 const StyledForm = styled('form') (({ theme }) => ({
    '& .form': {
        width: '100%',
        marginTop: theme.spacing.unit,
    },
    '& .progressCircle': {
        position: 'absolute',
        top: '50%',
        left: '50%'
    }
 }));

export const LoginPage = (props) => {
    const { classes, loggingIn, alert, companyInfo} = props;
    const [open, setOpen] = React.useState(false);
    const [authtype, setAuthtype] = React.useState('default');

  const handleClickOpen = (provider) => {
      if (provider === 'sso') {
        window.location = SSO_AUTH_URL;
      }
      else if (provider === 'google') {
        window.location = GOOGLE_AUTH_URL;
      }
      else if (provider === 'microsoft') {
        window.location = MICROSOFT_AUTH_URL;
      } else {
          setAuthtype(provider);
	  setOpen(true);
      }
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const listItems = props.AuthProviders.map(function(authprovider) {
      let authbutton = authprovider.title;
      return <Button type="submit" variant="outlined" className={'btn-white'} onClick={() => handleClickOpen(authbutton)}>{authprovider.display_name}</Button>
    });
    return (
        <main className={'login-page'}>
            <div className={'steps-container'}>
                    <div className={'logo-wrap'}>
                        <div className={'steps-logo'}>
                            <div className={'helper'}>
                                <img src="steps-logo.svg" alt="Steps Logo" />
                            </div>
                        </div>
                        <div className={'steps-info'}>Staff Timesheets, Expenses and Projects System</div>
                    </div>
            </div>
            <div className={'login-page-paper'}>
                <section className={"login-page__info-section"}>
                    <CompanyInfo 
                        info={ companyInfo }                  
                    />
                </section>
                <section className="login-page__form-section">
                    <div className={'title'}>Sign In</div>
                    <div className={'btns'}>
                        {listItems}
                    </div>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Sign In</DialogTitle>          
                        <StyledForm className={'form'} onSubmit={(e,mode) => props.onSubmit(e, authtype)}>
                            <DialogContent>          
                                <DialogContentText></DialogContentText>
                                {props.LoginError && <p className='error'>Invalid username or password</p>}
                                <FormControl variant="standard" className={(props.submitted ? ' has-error' : '')} margin="normal" fullWidth>
                                    <InputLabel htmlFor="email">Email Address</InputLabel>
                                    <Input id="username" name="username" autoComplete="email" value={props.username} onChange={props.onChange} autoFocus error={props.Errors.username && true}/>
                                    <small className='error'>{props.Errors.username}</small>
                                </FormControl>
                                <FormControl variant="standard" className={(props.submitted && !props.password ? ' has-error' : '')} margin="normal" fullWidth>
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <Input name="password" type="password" id="password" autoComplete="current-password" value={props.password} onChange={props.onChange} error={props.Errors.password && true} />
                                    <small className='error'>{props.Errors.password}</small>
                                </FormControl>
                                {loggingIn && <CircularProgress className={'progressCircle'} />}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">Cancel</Button>
                                <Button type="submit" variant="contained" color="primary">Sign in</Button>
                            </DialogActions>
                        </StyledForm>
                    </Dialog> 
                </section>
            </div>
        </main>
    )
}
