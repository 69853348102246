import React from 'react';

import {httpServices}  from '../../services/httpServices';
import {EligibilityCriteriaGroupsComponent}  from './EligibilityCriteriaGroups.Component';
const formFields = {
    'id':0,
    'name': '',
    'timeoff_type_id': '',
    'description': '',
    'is_default': 0,
    'start_year': '',
    'end_year': '',
};

const criteriaFormFields = {
    'id':0,
    'group_id': '',
    'unit': '',
    'lower_limit': 0,
    'upper_limit': '',
    'no_days': '',
};

export class EligibilityCriteriaGroupsView extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            criteraGroups : [],
            timeoffTypes : [],
            formFields : formFields,
            formFields : criteriaFormFields,
            open : false,
            isEditable : false,
            errors : {},
            popupType : 'add',
            hasPermission : true,
            editPermission : false,
            deletePermission : false,
            criteraUnits : [],
            expandedRow : '',
            dailyMinimum : '',
            snackbarOpen : false,
            snackbarMsg : '',
            snackbarMsgType : 'success',
        };
        this.editGroupClick = this.editGroupClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCriteriaSubmit = this.handleCriteriaSubmit.bind(this);
        this.onAddGroup = this.onAddGroup.bind(this);
        this.deleteGroupClick = this.deleteGroupClick.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.onAddCriterion = this.onAddCriterion.bind(this);
        this.handleOnCriteriaChange = this.handleOnCriteriaChange.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.editCriterionClick = this.editCriterionClick.bind(this);
        this.deleteCriterionClick = this.deleteCriterionClick.bind(this);
        this.deleteCriterion = this.deleteCriterion.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }

    componentDidMount = () => {
        const clicked_group = localStorage.getItem('clicked_group');
        if (clicked_group !== undefined) {
            localStorage.removeItem('clicked_group');
            this.setState({
                expandedRow: parseInt(clicked_group)
            },function(){
                this.getAllGroups();
            });
        } else {
            this.getAllGroups();
        }
    };

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        if (evt.target.type && evt.target.type === 'checkbox') {
            if(evt.target.value == 1) {
                ff[field] = 0;
            }
            else {
                ff[field] = 1;
            }
        } else {
            ff[field] = evt.target.value;
        }
        
        this.setState({
            formFields : ff
        });
    };

    onClose = () => {
        this.setState({
            open : false,
            errors : {}
        });
    };

    onAddGroup = () => {
        let formFields = {
            'id':0,
            'name': '',
            'timeoff_type_id': '',
            'description': '',
            'is_default': 0,
            'start_year': '',
            'end_year': ''
        };
        this.setState({
            open : true,
            formFields : formFields,
            isEditable : false,
            popupType : 'add',
        });
    };

    handleOnCriteriaChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.criteriaFormFields;
        if (evt.target.type && evt.target.type === 'checkbox') {
            if(evt.target.value == 1) {
                ff[field] = 0;
            }
            else {
                ff[field] = 1;
            }
        } else {
            ff[field] = evt.target.value;
        }

        this.setState({
            criteriaFormFields : ff
        });
    };
    handleRowClick = (groupId) => () => {
        let prevSelection = this.state.expandedRow;
        this.setState({
            expandedRow : (prevSelection != groupId) ? groupId : ''
        });
    };

    onAddCriterion = (group_id, group_name) => () => {
        let criteriaFormFields = {
            'id':0,
            'group_id': group_id,
            'unit': '',
            'lower_limit': 0,
            'upper_limit': '',
            'no_days': '',
            'group_name' : group_name
        };
        this.setState({
            open : true,
            criteriaFormFields : criteriaFormFields,
            isEditable : false,
            popupType : 'add-criteria',
        });
    };

    deleteGroupClick = (id) => () => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteGroupId : id,
            });
        }
    };

    deleteCriterionClick = (id) => () => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete-criteria',
                deleteCriterionId : id,
            });
        }
    };

    getAllGroups = () => {
        let apiEndpoint = 'api/settings/getAllEligibilityCriteriaGroups';
        return httpServices.get(apiEndpoint)
        .then(response => {
                if (response.data.permission) {
                    if (response.data.criteria_groups && response.data.criteria_groups.length) {
                        this.setState({
                            criteraGroups : response.data.criteria_groups,
                            hasPermission : response.data.permission,
                            editPermission : response.data.edit_permission,
                            deletePermission : response.data.delete_permission,
                            timeoffTypes : response.data.timeoff_types,
                            criteraUnits : response.data.critera_units,
                            dailyMinimum : response.data.daily_minimum
                        });
                } else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            }
            else {
                this.setState({
                    hasPermission : false,
                });
            }
        })
        .catch(function(error) {
           console.log("Error: "+error);
         });
    };

    editGroupClick = (id) => () => {
        let apiEndpoint = 'api/settings/getEligibilityCriteriaGroupById';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.group_det) {
                        this.setState({
                            formFields : response.data.group_det,
                            open : true,
                            isEditable : true,
                            popupType : 'add',
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;            
            if (data) {
                 httpServices.post('api/settings/saveEligibilityCriteriaGroup', { data })
                    .then(response => {
                        if (response.data.status) {
                            if(data.id){
                                // Update DOM
                                let criteria_groups = (response.data.criteria_groups !== undefined) ? response.data.criteria_groups : this.state.criteraGroups;
                                this.setState({
                                    criteraGroups : criteria_groups,
                                    open : false,
                                    snackbarOpen : true,
                                    snackbarMsg : "Time Off Policy updated successfully",
                                    snackbarMsgType : 'success',
                                });
                            } else {
                                let criteria_groups = (response.data.criteria_groups !== undefined) ? response.data.criteria_groups : this.state.criteraGroups;
                                this.setState({
                                    criteraGroups : criteria_groups,
                                    open : false,
                                    snackbarOpen : true,
                                    snackbarMsg : "Time Off Policy saved successfully",
                                    snackbarMsgType : 'success',
                                });
                            }
                        } else {
                            if (response.data.validation_errors) {
                                this.setState({ errors: response.data.validation_errors });
                            }
                            else {
                                console.log("Save failed.");
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.name.trim()) {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.is_default === 1 && this.state.formFields.start_year == '') {
            errors["start_year"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.timeoff_type_id) {
            errors["timeoff_type_id"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    deleteGroup = () => {
        let del_id = this.state.deleteGroupId;
        if (del_id) {
            let apiEndpoint = 'api/settings/deleteEligibilityCriteriaGroup';
            return httpServices.post(apiEndpoint,{id : del_id})
                .then(response => {
                    // Dom updation
                    if (response.data.status) {
                        let criteria_groups = (response.data.criteria_groups !== undefined) ? response.data.criteria_groups : this.state.criteraGroups;
                        this.setState({
                            criteraGroups : criteria_groups,
                            open : false,
                            snackbarOpen : true,
                            snackbarMsg : "Time Off Policy deleted successfully",
                            snackbarMsgType : 'error',
                        });
                    } else {
                        this.setState({
                            open : false,
                            snackbarOpen : true,
                            snackbarMsg : response.data.err_msg,
                            snackbarMsgType : 'error',
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    };

    editCriterionClick = (id) => () => {
        let apiEndpoint = 'api/settings/getEligibilityCriterionById';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.criterion_det) {
                        this.setState({
                            criteriaFormFields : response.data.criterion_det,
                            open : true,
                            isEditable : true,
                            popupType : 'add-criteria',
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleCriteriaSubmit = (event) => {
        if(this.handleCriteriaValidation()){
            let data = this.state.criteriaFormFields;
            if (data) {
                 httpServices.post('api/settings/saveEligibilityCriterion', { data })
                    .then(response => {
                        if (response.data.status) {
                            if(data.id){
                                // Update DOM
                                let criteria_groups = (response.data.criteria_groups !== undefined) ? response.data.criteria_groups : this.state.criteraGroups;
                                this.setState({
                                    criteraGroups : criteria_groups,
                                    open : false,
                                    snackbarOpen : true,
                                    snackbarMsg : "Time Off Policy Criteria updated successfully",
                                    snackbarMsgType : 'success',
                                });
                            } else {
                                let criteria_groups = (response.data.criteria_groups !== undefined) ? response.data.criteria_groups : this.state.criteraGroups;
                                this.setState({
                                    criteraGroups : criteria_groups,
                                    open : false,
                                    snackbarOpen : true,
                                    snackbarMsg : "Time Off Policy Criteria saved successfully",
                                    snackbarMsgType : 'success',
                                    expandedRow : (data.group_id !== undefined) ? data.group_id : ''
                                });
                            }
                        } else {
                            if (response.data.validation_errors) {
                                this.setState({ errors: response.data.validation_errors });
                            }
                            else {
                                console.log("Save failed.");
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleCriteriaValidation() {
        let formIsValid = true;
        let errors = {};
        if (this.state.criteriaFormFields.lower_limit === null || this.state.criteriaFormFields.lower_limit === undefined || this.state.criteriaFormFields.lower_limit === '') {
            errors["lower_limit"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.criteriaFormFields.no_days) {
            errors["no_days"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.criteriaFormFields.unit.trim()) {
            errors["unit"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    deleteCriterion = () => {
        let del_id = this.state.deleteCriterionId;
        if (del_id) {
            let apiEndpoint = 'api/settings/deleteEligibilityCriterion';
            return httpServices.post(apiEndpoint,{id : del_id})
                .then(response => {
                    // Dom updation
                    if (response.data.status) {
                        let criteria_groups = (response.data.criteria_groups !== undefined) ? response.data.criteria_groups : this.state.criteraGroups;
                        this.setState({
                            criteraGroups : criteria_groups,
                            open : false,
                            snackbarOpen : true,
                            snackbarMsg : "Time Off Policy Criteria deleted successfully",
                            snackbarMsgType : 'error',
                        });
                    } else {
                        this.setState({
                            open : false,
                            snackbarOpen : true,
                            snackbarMsg : response.data.err_msg,
                            snackbarMsgType : 'error',
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    };

    render(){
        return (
            <EligibilityCriteriaGroupsComponent 
                EditGroupClick = {this.editGroupClick}
                Open = {this.state.open}
                IsEditable = {this.state.isEditable}
                FormFields = {this.state.formFields}
                HandleOnChange = {this.handleOnChange}
                OnClose = {this.onClose}
                HandleSubmit = {this.handleSubmit}
                Errors = {this.state.errors}
                OnAddGroupClick = {this.onAddGroup}                
                PopupType = {this.state.popupType}
                DeleteGroupClick = {this.deleteGroupClick}
                DeleteGroup = {this.deleteGroup}
                HasPermission = {this.state.hasPermission}
                EditPermission = {this.state.editPermission}
                DeletePermission = {this.state.deletePermission} 
                CriteraGroups = {this.state.criteraGroups}     
                TimeoffTypes = {this.state.timeoffTypes}
                OnAddCriterionClick = {this.onAddCriterion}
                CriteriaFormFields = {this.state.criteriaFormFields}
                HandleCriteriaSubmit = {this.handleCriteriaSubmit}
                HandleOnCriteriaChange = {this.handleOnCriteriaChange}
                CriteraUnits = {this.state.criteraUnits}
                HandleRowClick = {this.handleRowClick}
                ExpandedRow = {this.state.expandedRow}
                DailyMinimum = {this.state.dailyMinimum}
                EditCriterionClick = {this.editCriterionClick}
                DeleteCriterionClick = {this.deleteCriterionClick}
                DeleteCriterion = {this.deleteCriterion}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                SnackbarMsgType = {this.state.snackbarMsgType}
                SnackbarClose = {this.snackbarClose}
            />
        )
    }
}
