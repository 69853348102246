import React from 'react';

import {httpServices}  from '../../services/httpServices';
import {ActionDetailsComponent} from './ActionDetailsComponent';
import { StaffConstants } from '../../constants';
import {history} from './../../helpers/history';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;

export class ActionDetails extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            action_details : {},
            roles : {}
        };
    }
    componentDidMount = () => {
        let id = this.props.match.params.id;
        if (id) {
            this.getActionDetails(id);
        }
        this.viewRoleDetails = this.viewRoleDetails.bind(this);
    };
    getActionDetails = (id) => {
        let apiEndpoint = 'api/system/role/get-action-details';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                    if (response.data) {
                        this.setState({
                            action_details : response.data.action_details,
                            roles : response.data.roles
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    viewRoleDetails  = (roleId) => () =>{
        history.push(BASE_PATH+'/system/'+StaffConstants.ROLE_DETAILS_PAGE + '/' + roleId);
    }   
    render(){
        return(   
            <ActionDetailsComponent 
                ActionDetails = {this.state.action_details}
                Roles = {this.state.roles}
                ViewRoleDetails = {this.viewRoleDetails}
            />   
        )
    }
}

