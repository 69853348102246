import React from 'react';
import {SubmitComponent} from './Submit.Component';
import { httpServices } from '../../../services/httpServices';
import { history } from './../../../helpers/history';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const filterFields = {
    'startDate': null,
    'endDate': null,
    'status' : -1,
};
export class SubmitExpenseContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            hasPermission : false,
            expenseReports : [],
            open : false,
            submittedAmountTotal : 0,
            actualPaymentTotal : 0,
            viewId :null,
            viewClick : false,
            startYear : '',
            endYear : '',
            filterViewState : false,
            filterFields : filterFields,
            expenseStatus :[],
            snackbarOpen : false,
            snackbarMsg : '',
            openViewDetails : false,
            expenseReportId : null,
            permisionError : '',
            fromSubmitTab : false,
            showLoader : true,
            dataLoaded : false,
            errors : {},
        }
        this.filterView = this.filterView.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleFilterDateChange = this.handleFilterDateChange.bind(this);
    }
    componentDidMount = () => {
        let filterFields = this.state.filterFields;
        return httpServices.get('api/expenses/submit')
            .then(response => {
                if(response.data)
                {
                    if(response.data.permission)
                    {
                        if (filterFields.startDate === null)
                        {
                            filterFields.startDate = response.data.start_date;
                        }
                        if (filterFields.endDate === null)
                        {
                            filterFields.endDate = response.data.end_date;
                        }
                        this.setState({
                            hasPermission : true,
                            expenseStatus : response.data.status,
                            filterViewState : false,
                            startYear : response.data.start_year,
                            endYear : response.data.end_year,
                            filterFields : filterFields,
                        },()=>{ 
                            this.getExpenseSubmitDetails();
                        });
                    }
                    else
                    {
                        this.setState({
                            showLoader : false,
                            dataLoaded : true,
                            permisionError: "You are not authorized to do this action"
                        });
                    }
                }
            })
    };

    handleFilterDateChange = (date, name) => {
        const f = this.state.filterFields;
        f[name] = date;
        this.setState({
            filterFields: f
        })
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (this.state.filterFields.startDate > this.state.filterFields.endDate) {
            errors["endDate"] = "End date should be greater than start date";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    getExpenseSubmitDetails = () =>{
        let apiEndpoint = 'api/expenses/submit';
        return httpServices.post(apiEndpoint,{'start_date' : this.state.filterFields.startDate, 'end_date' : this.state.filterFields.endDate, 'report_status' : this.state.filterFields.status})
            .then(response => { 
                if(response.data)
                {
                    if(response.data.permission===false)
                    { 
                        this.setState({
                            permisionError: "You are not authorized to do this action"
                        });
                    }
                    else{
                        this.setState({
                            expenseReports : response.data.expense_reports,
                            submittedAmountTotal : response.data.submitted_amount_total,
                            actualPaymentTotal : response.data.actual_payment_total,
                            filterViewState : false,
                            showLoader : false,
                            dataLoaded : true,
                        });
                    }
                }
               // else {
                 //   this.setState({
                        //hasPermission : false,
                   // });

               // }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
     
    handleOnChange = (evt, value) => {
        const f = this.state.filterFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            filterFields: f
        });
    }
    handleViewClick = (id) => {
        if(id) {
             history.push(BASE_PATH+'/expenses/submit/' + id);
        }
    }
    filterView = (anchor, open) => (event) => {
        this.setState({filterViewState : open});
    };
    filterSubmit = () =>{
        if (this.handleValidation()) {
            this.setState({
                showLoader : true,
            },()=>{
                this.getExpenseSubmitDetails();
            });
        }
    }
    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.hasPermission) ?
                        <SubmitComponent
                            ExpenseReport = {this.state.expenseReports}
                            ExpenseStatus = {this.state.expenseStatus}
                            submittedAmountTotal = {this.state.submittedAmountTotal}
                            actualPaymentTotal = {this.state.actualPaymentTotal}
                            FilterFields =  {this.state.filterFields}
                            startYear = {this.state.startYear}
                            endYear = {this.state.endYear}
                            Open = {this.state.open}
                            HandleViewClick = {this.handleViewClick}
                            HandleOnChange = {this.handleOnChange}
                            FilterView = {this.filterView}
                            FilterViewState = {this.state.filterViewState}
                            FilterSubmit = {this.filterSubmit}
                            SnackbarOpen = {this.state.snackbarOpen}
                            SnackbarMsg = {this.state.snackbarMsg}
                            SnackbarClose = {this.snackbarClose}
                            permisionError={this.state.permisionError}
                            HandleFilterDateChange = {this.handleFilterDateChange}
                            errors = {this.state.errors}
                        />
                        :
                        <ErrorPage/>
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}
