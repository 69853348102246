import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux'
import cookie from 'react-cookies';
// Components
import store from './redux/store/store';
import { AppBarMain } from './components/AppBarMain';
import {history, PrivateRoute, getThirdPartyLoginDetails} from './helpers';
import { LoginPage } from './modules/login/login.container';
import { StaffList, StaffView} from './modules/staff';
import { ComponentsView,JobLevel,StateCity,Yearly401kSettingsView, ExpenseSettingsContainer,TimeOffSettingsContainer,TimeofftypesViewContainer,WorkpolicySettingsContainer, PolicyViewContainer, SyncInstancesContainer,InstanceDetailsContainer, OfficeContainer, ClearanceContainer, EligibilityCriteria} from './modules/settings';
import { PayrollContainer, PayrollGenerateContainer, ReviewContainer, ImportContainer, ImportEdenRedStatementContainer, EdenredDetailsContainer } from './modules/payroll';
import { DashboardContainer} from './modules/dashboard';
import { TimeOffContainer, LocationContainer, OvertimeContainer, AdditionalptoContainer, RequestDashboardContainer, TimeOffViewContainer, AdditionalptoDetailsContainer, LocationViewDetails, RequestEmailActionContainer, ViewOvertimeDetails, RequestCalendar} from './modules/requests';
import { AddExpenseContainer, SubmitExpenseContainer, ReviewExpenseContainer, ImportExpenseContainer, ViewExpenseDetailsContainer,ExpenseVoucherContainer} from './modules/expenses';
import { Reports, IifReportContainer, InvoiceReportContainer, UtilizationReportContainer, StaffDetailsReportContainer, TimesheetDelinquencyReportContainer, ChargeabilityReportContainer, StaffCountReportContainer , BonusReportContainer, WorkHoursReportContainer, TimesheetSummaryReportContainer, ProjectStatusReportContainer, TimesheetExportContainer, CustomReportContainer, WorkHoursEffectivenessReportContainer, weeklyOnsiteOffsiteReportContainer, TimesheetLateSubmissionReportComponent, DailyHoursSummaryReportContainer, ptoRolloverReportContainer} from './modules/reports';
import {ProjectCodeReportContainer} from './modules/reports/ProjectCodeReport.Container';
import {TimeoffLogReportContainer} from './modules/reports/TimeoffLogReport.Container';
import { ProjectsContainer, ProjectViewContainer, ClientViewContainer, ClientListContainer } from './modules/projects';
import { ApiErrorPage } from './modules/common/apiError';
import { SubmitListContainer, SubmitContainer, TimeEntryContainer, ReviewListContainer, TimesheetReviewContainer, SearchInOutContainer,TimeOffDetailsContainer, TimeOffListContainer } from './modules/timesheet';
import { SystemRole,AuditLog, RoleDetails, ReportConfig, RequestConfig, ReportDetailsContainer, SystemConfiguration,Announcements,AnnouncementView,  CompanyDetailsContainer, EmailNotification, EmailNotificationDetails, HolidayContainer, System, Functional, Users, ActionDetails} from './modules/system';
import { ProfileContainer } from './modules/myprofile';
import { loadReCaptcha } from 'react-recaptcha-google';
import { ViewMessage, MessagesList } from './modules/messages';
import { ViewAnnouncement } from './modules/announcements';
import { ReimbursementReportContainer } from './modules/reports/ReimbursementReport.Container';
import { PayperiodStatusReportContainer } from './modules/reports/payperiodStatusReport/PayperiodStatusReport.Container';
import { TimeSheetDetailsContainer } from './modules/reports/payperiodStatusReport/TimeSheetDetails.Container';
import { ActualEstimateReportContainer } from './modules/reports/actualVsEstimate/ActualEstimateReport.Container';
import { GroupListContainer,GroupViewContainer,UserListContainer, UserAddContainer, UserViewContainer, UserEditContainer} from './modules/users';
import { createJiraWidget } from './modules/common/jiraHelpDesk';
import { UnderMaintenanceContainer } from './modules/common/UnderMaintenance';
const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
const JIRA_WIDGET_DATA_KEY = process.env.REACT_APP_JIRA_WIDGET_DATA_KEY;
// Initialize captcha at top root.
loadReCaptcha();
// Tracking page navigations for google analytics.
history.listen((location) => {
    if (window.ga) { 
        if (location.pathname) {
            window.ga('set', 'page', location.pathname);
            window.ga('send', {
                hitType: 'pageview',
            });
        }
    }
});
const DefaultRouteContainer = () => (
    <>
        <AppBarMain path={window.location.pathname} />
        <div className="main-container">
            <Switch> 
                <PrivateRoute exact path={BASE_PATH+"/staff"} component={StaffList} />
                <PrivateRoute exact path={BASE_PATH+"/staff/view/:id"} component={StaffView} />
                <PrivateRoute exact path={BASE_PATH+"/settings"} component={ComponentsView} />
                <PrivateRoute exact path={BASE_PATH+"/system/functional/component"} component={ComponentsView} />
                <PrivateRoute exact path={BASE_PATH+"/system/functional/level"} component={JobLevel} />
                <PrivateRoute exact path={BASE_PATH+"/system/functional/state"} component={StateCity} />
                <PrivateRoute exact path={BASE_PATH+"/settings/yearly-settings"} component = {Yearly401kSettingsView} />
                <PrivateRoute exact path={BASE_PATH+"/system/functional/expense"} component = {ExpenseSettingsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/functional/timeoff"} component={TimeOffSettingsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/functional/timeoff/view/:id"} component={TimeofftypesViewContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/functional/eligibilitycriteria"} component={EligibilityCriteria} />
                <PrivateRoute exact path={BASE_PATH+"/settings/office"} component={OfficeContainer} />
                <PrivateRoute exact path={BASE_PATH+"/payroll"} component={PayrollContainer} />
                <PrivateRoute exact path={BASE_PATH+"/payroll/generate"} component={PayrollGenerateContainer} />
                <PrivateRoute exact path={BASE_PATH+"/payroll/review"} component={ReviewContainer} />
                <PrivateRoute exact path={BASE_PATH+"/payroll/review/:id"} component={ReviewContainer} />
                <PrivateRoute exact path={BASE_PATH+"/payroll/review/:id/:status"} component={ReviewContainer} />
                <PrivateRoute exact path={BASE_PATH+"/payroll/import"} component={ImportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/dashboard"} component={DashboardContainer} />
                <PrivateRoute exact path={BASE_PATH+"/requests/timeoff"} component={TimeOffContainer} />
                <PrivateRoute exact path={BASE_PATH+"/requests/overtime"} component={OvertimeContainer} />
                <PrivateRoute exact path={BASE_PATH+"/requests/location"} component={LocationContainer} />
                <PrivateRoute exact path={BASE_PATH+"/requests/additionalpto"} component={AdditionalptoContainer} />
                <PrivateRoute exact path={BASE_PATH+"/requests/dashboard"} component={RequestDashboardContainer} />
                <PrivateRoute exact path={BASE_PATH+"/requests/calendar"} component={RequestCalendar} />
                <PrivateRoute exact path={BASE_PATH+"/requests/timeoffdetails"} component={TimeOffListContainer} />
                <PrivateRoute exact path={BASE_PATH+"/requests/timeoffdetails/view/:id"} component={TimeOffDetailsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/list"} component={Reports} />
                <PrivateRoute exact path={BASE_PATH+"/reports/invoice-report"} component={InvoiceReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/utilization-report"} component={UtilizationReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/project-code-report"} component={ProjectCodeReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/projects/list"} component={ProjectsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/expenses/add"} component={AddExpenseContainer} />
                <PrivateRoute exact path={BASE_PATH+"/expenses/submit"} component={SubmitExpenseContainer} />
                <PrivateRoute exact path={BASE_PATH+"/expenses/review"} component={ReviewExpenseContainer} />
                <PrivateRoute exact path={BASE_PATH+"/expenses/import"} component={ImportExpenseContainer} />
                <PrivateRoute exact path={BASE_PATH+"/timesheet/submit"} component={SubmitListContainer} />
                <PrivateRoute exact path={BASE_PATH+"/timesheet/time-entry"} component={TimeEntryContainer} />
                <PrivateRoute exact path={BASE_PATH+"/timesheet/submit/:id"} component={SubmitContainer} />
                <PrivateRoute exact path={BASE_PATH+"/timesheet/review"} component={ReviewListContainer} />
                <PrivateRoute exact path={BASE_PATH+"/timesheet/searchinandout"} component={SearchInOutContainer} />
                <PrivateRoute exact path={BASE_PATH+"/timesheet/review/:id"} component={TimesheetReviewContainer} />
                <PrivateRoute exact path={BASE_PATH+"/server-error"} component={ApiErrorPage} />
                <PrivateRoute exact path={BASE_PATH+"/reports/timesheet-iif-report"} component={IifReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/employee-chargeability-report"} component={ChargeabilityReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/consolidated-salary-bonus-report"} component={BonusReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/work-hours-report"} component={WorkHoursReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/custom-report"} component = {CustomReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/weekly-onsite-offsite"} component = {weeklyOnsiteOffsiteReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/roles"} component={SystemRole} />
                <PrivateRoute exact path={BASE_PATH+"/system/functional"} component={Functional} />
                <PrivateRoute exact path={BASE_PATH+"/system/list"} component={System} />
                <PrivateRoute exact path={BASE_PATH+"/system/auditlog"} component={AuditLog} />
                <PrivateRoute exact path={BASE_PATH+"/system/roledetails/:id"} component={RoleDetails} />
                <PrivateRoute exact path={BASE_PATH+"/system/actiondetails/:id"} component={ActionDetails} />
                <PrivateRoute exact path={BASE_PATH+"/system/notificationview/:id"} component={EmailNotificationDetails} />
                <PrivateRoute exact path={BASE_PATH+"/system/reportconfig"} component={ReportConfig} />
                <PrivateRoute exact path={BASE_PATH+"/reports/staff-details-report"} component={StaffDetailsReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/timesheet-delinquency-report"} component={TimesheetDelinquencyReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/staff-count-report"} component={StaffCountReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/payroll/importedenredstatement"} component={ImportEdenRedStatementContainer} />
                <PrivateRoute exact path={BASE_PATH+"/payroll/importedenredstatement/edenreddetails/:month"} component={EdenredDetailsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/profile"} component={ProfileContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/requestconfig"} component={RequestConfig} />
                <PrivateRoute exact path={BASE_PATH+"/system/emailnotification"} component={EmailNotification} />
                <PrivateRoute exact path={BASE_PATH+"/requests/timeoff/view/:id"} component={TimeOffViewContainer} />
                <PrivateRoute exact path={BASE_PATH+"/requests/location/view/:id"} component={LocationViewDetails} />
                <PrivateRoute exact path={BASE_PATH+"/expenses/:action/:id"} component={ViewExpenseDetailsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/requests/additionalpto/view/:id"} component={AdditionalptoDetailsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/requests/overtime/view/:id"} component={ViewOvertimeDetails} />
                <PrivateRoute exact path={BASE_PATH+"/profile/messages/view/:id"} component={ViewMessage} />
                <PrivateRoute exact path={BASE_PATH+"/announcements/view/:id"} component={ViewAnnouncement} />
                <PrivateRoute exact path={BASE_PATH+"/expenses/vouchers"} component={ExpenseVoucherContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/reimbursement-report"} component={ReimbursementReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/timesheet/timeoff"} component={TimeOffListContainer} />
                <PrivateRoute exact path={BASE_PATH+"/timesheet/timeoff/view/:id"} component={TimeOffDetailsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/project-status-report"} component={ProjectStatusReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/timesheet-summary-report"} component={ TimesheetSummaryReportContainer } />
                <PrivateRoute exact path={BASE_PATH+"/reports/pay-period-status-report"} component={PayperiodStatusReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/payperiod/:id"} component={TimeSheetDetailsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/actual-estimate-hours-report"} component={ActualEstimateReportContainer} /> 
                <PrivateRoute exact path={BASE_PATH+"/reports/reconcile-timesheet-export"} component={TimesheetExportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/work-hours-effectiveness-report"} component={ WorkHoursEffectivenessReportContainer } />
                <PrivateRoute exact path={BASE_PATH+"/projects/view/:id"} component={ProjectViewContainer} />
                <PrivateRoute exact path={BASE_PATH+"/projects/clients/view/:id"} component={ClientViewContainer} />
                <PrivateRoute exact path={BASE_PATH+"/projects/clients"} component={ClientListContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/users/groups"} component={GroupListContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/users/add"} component={UserAddContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/users/groups/view/:id"} component={GroupViewContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/users/list"} component={UserListContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/timesheet-late-submission-report"} component={TimesheetLateSubmissionReportComponent} />
                <PrivateRoute exact path={BASE_PATH+"/system/reportconfig/details/:id"} component={ReportDetailsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/daily-hours-summary"} component={DailyHoursSummaryReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/reports/timeofflog-report"} component={TimeoffLogReportContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/users/view/:id"} component={UserViewContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/user/edit/:id"} component={UserEditContainer} />
                <PrivateRoute exact path={BASE_PATH+"/profile/messages"} component={MessagesList} />
                <PrivateRoute exact path={BASE_PATH+"/system/configuration"} component={SystemConfiguration} />
                <PrivateRoute exact path={BASE_PATH+"/system/announcements"} component={Announcements} />
                <PrivateRoute exact path={BASE_PATH+"/system/announcements/view/:id"} component={AnnouncementView} />
                <PrivateRoute exact path ={BASE_PATH+"/system/companydetails"} component = {CompanyDetailsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/functional/holiday"} component={HolidayContainer} />
                <PrivateRoute exact path ={BASE_PATH+"/reports/pto-rollover-report"} component = {ptoRolloverReportContainer} /> 
                <PrivateRoute exact path={BASE_PATH+"/system/functional/workpolicy"} component={WorkpolicySettingsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/functional/workpolicy/policy/view/:id"} component={PolicyViewContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/users"} component={Users} /> 
                <PrivateRoute exact path={BASE_PATH+"/system/syncinstance"} component={SyncInstancesContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/instancedetails"} component={InstanceDetailsContainer} />
                <PrivateRoute exact path={BASE_PATH+"/system/functional/clearance"} component={ClearanceContainer} />
                { /* Default route */}
                <PrivateRoute component={DashboardContainer} />
            </Switch>
        </div>
    </>
)

if(cookie.load(AUTH_KEY) ) {
    //history.push(BASE_PATH + StaffConstants.STAFF_INDEX_PAGE);
}

if(cookie.load(AUTH_KEY) && typeof JIRA_WIDGET_DATA_KEY !== 'undefined' && JIRA_WIDGET_DATA_KEY !== '') {
    createJiraWidget();
}

const Root = () => (
  <Provider store={store}>
    <Router history={history}>
        <CssBaseline />
        <Switch>
            // Public routes
            <Route path={BASE_PATH + "/under-maintenance"} component={UnderMaintenanceContainer} />
            <Route path ={BASE_PATH+"/requests/emailaction/:action/:id"}  component = { RequestEmailActionContainer } />
            { !cookie.load(AUTH_KEY)  && <Route path={BASE_PATH+"/login"} component={LoginPage} /> }
            // Private routes
            <PrivateRoute component={DefaultRouteContainer} />
            {/*
            <PrivateRoute path="/timesheet-v2/public/dashboard" component={DefaultRouteContainer} />
            <Route path="/timesheet-v2/public/login" component={LoginPage} />
            <PrivateRoute path="*" component={LoginRouteContainer} />*/}
        </Switch>
    </Router>
  </Provider>
)

export default Root;
