import React from 'react';
import { TaskComponent } from './Task.Component';
import { httpServices } from '../../../services/httpServices';
import { makeOptGroups } from '../../common/ls';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { formatName } from '../../common/common';
import MixpanelManager from  '../../../services/Analytics';

const formValues = {
    task_name : '',
    task_description : '',
    type : '1', // 1 - Working - default
    timeoff_type : '',
    status : '1', // 1 - Active
    start_date : '',
    end_date : '',
    tags : [],
    resources : [],
    clin : [],
    sent_notification : true,
};
const filterValues = {
    status : '1',
    type : '0',
};

let DefaultStartDate = '';
let DefaultEndDate = '';
export class TaskContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            projectId : 0,
            formInputs : {},
            popupType : '',
            dialogOpen : false,
            formValues : formValues,
            tags : [],
            showTimeoffTypes : false,
            Errors : {},
            editId : 0,
            snackbarOpen : false,
            snackbarVariant : 'success',
            snackbarMsg : '',
            moveTaskProjectId : 0,
            moveTaskId : 0,
            permissionErr : false,
            endDateConfirmation : false,
            showLoader : false,
            right: false,
            filterInputs : [],
            filterValues : filterValues,
            updateResource : {},
            taskWithClin: false,
            nameFormat : 'FIRSTLAST',
            nameSortOrder : 'ASC'
        };
        this.getProjectTasks = this.getProjectTasks.bind(this);
        this.handleResourceStatusFilterChange = this.handleResourceStatusFilterChange.bind(this);
        this.handleResourceChange = this.handleResourceChange.bind(this);
        this.handleTaskChange = this.handleTaskChange.bind(this);
        this.updateTaskResources = this.updateTaskResources.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.onSelectResources = this.onSelectResources.bind(this);
        this.validateTaskData = this.validateTaskData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getEditTaskDetails = this.getEditTaskDetails.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.popupClose = this.popupClose.bind(this);
        this.deleteTask = this.deleteTask.bind(this);
        this.handleProjectChange = this.handleProjectChange.bind(this);
        this.moveTask = this.moveTask.bind(this);
        this.handleEndDateConfirmation = this.handleEndDateConfirmation.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleResourceEditClick = this.handleResourceEditClick.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }
    componentDidMount = () => {
        if (this.props.ProjectId) {
            this.setState({
                projectId : this.props.ProjectId,
            }, function(){
                this.getProjectTasks();         
            });
        }
    }
    componentWillUnmount () {
        // Cleaning the mounted component just before leaving.
        for (const key in formValues) {
            if (key === 'type' || key === 'status') {
                formValues[key] = '1';
            } 
            if (key === 'sent_notification') {
                formValues[key] = true;
            }
            else {
                formValues[key] = '';
            }
        }
        for (const flkey in filterValues) {
            if (flkey === 'status') {
                filterValues[flkey] = '1';
            } else {
                filterValues[flkey] = '0';
            }
        }
    }
    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }
    getProjectTasks = () => {
        let projectId = this.state.projectId; 
        if (projectId) {
            this.setState({
                showLoader : true,
                right: false,
            });
            const data = this.state.filterValues;
            data.project_id = projectId;
            return httpServices
                .post("api/project/getprojecttasks", data)
                .then((response) => {
                    if (response.data && response.data.valid) {
                        let formValues = this.state.formValues;
                        formValues.start_date = response.data.start_date;
                        formValues.sent_notification = true;
                        DefaultStartDate = response.data.start_date;
                        if (response.data.end_date) {
                            formValues.end_date = response.data.end_date;
                            DefaultEndDate = response.data.end_date;
                        }
                        if (response.data.all_projects && response.data.all_projects.length) {
                            response.data.all_projects = makeOptGroups(response.data.all_projects);
                        }
                        let filterInputs = this.state.filterInputs;
                        filterInputs.task_type = response.data.task_type;
                        filterInputs.statuses = response.data.task_status;
                        filterInputs.all_lables = response.data.all_lables;
                        this.setState({
                            formInputs : response.data,
                            showLoader : false,
                            filterInputs : filterInputs,
                        });
                    } else {
                        this.setState({
                            permissionErr : true,
                            showLoader : false,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    
    handleTaskChange = (evt,value) => {
        let ff = this.state.formValues;
        ff['tags'] = value;
        this.setState({
            formValues: ff 
            });
        };
    
    handleResourceChange = (selected,taskId) => {
        let tasks = this.state.formInputs.tasks;
        let taskIndex = tasks.findIndex(x => x.task_id === taskId);
        let task = tasks[taskIndex];
        let existingUserIds = (task['task_user_ids_prev'] && task['task_user_ids_prev'].length) ? task['task_user_ids_prev'].sort() : [];
        let tempSelectedIds = (selected && selected.length) ? selected.sort() : [];
        let difference = (JSON.stringify(existingUserIds) !== JSON.stringify(tempSelectedIds)) ? true : false;
        task['isDataChanged'] = difference;
        task['task_user_ids'] = selected;
        tasks[taskIndex] = task;
        let formInputs = this.state.formInputs;
        formInputs.tasks = tasks;
        // Update resource dual box
        let selectedResources = selected;
        let updateResource = this.state.updateResource;
        updateResource.selected = selectedResources;
        this.setState({
            formInputs : formInputs,
            updateResource : updateResource
        });
    }
    updateTaskResources = () => {
        let updateResource = this.state.updateResource;
        let taskId = updateResource.taskId;
        let tasks = this.state.formInputs.tasks;
        let taskIndex = tasks.findIndex(x => x.task_id === taskId);
        let task = tasks[taskIndex];
        let resources = JSON.stringify(task.task_user_ids);
        let sent_notification = this.state.formValues.sent_notification;
        this.setState({
            showLoader : true,
        });
        if (taskId) {
            return httpServices
                .post("api/project/save-task-users", {task_id: taskId,resources: resources, sent_notification:sent_notification})
                .then((response) => {
                    if (response.data) {
                        let msg = "Resources updated successfully";
                        this.setState({
                            snackbarOpen : true,
                            snackbarVariant : 'success',
                            snackbarMsg : msg,
                            updateResource : {},
                            popupType : '',
                            dialogOpen : false,
                            showLoader : false,
                        }, function () {
                            this.getProjectTasks(); // Update Dom
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    handleResourceEditClick = (taskId) => {
        if (taskId) {
            let tasks = this.state.formInputs.tasks;
            let taskIndex = tasks.findIndex(x => x.task_id === taskId);
            let task = tasks[taskIndex];
            let selectedResources = (task['task_user_ids'] && task['task_user_ids'].length) ? task['task_user_ids'] : [];
            let projectResources = (typeof this.state.formInputs.project_resources !== 'undefined') ? this.state.formInputs.project_resources : [];
            let tempProjectResorces = [];
            let displayNameFormat = this.state.nameFormat;
            projectResources.forEach(function(obj,idx){
                obj.value = obj.user_id;
                obj.label = formatName(obj.user_first_name,null,obj.user_last_name);
                tempProjectResorces.push(obj);
            });
            let updateResource = this.state.updateResource;
            updateResource.taskId = taskId;
            updateResource.available = tempProjectResorces;
            updateResource.selected = selectedResources;
            this.setState({
                dialogOpen : true,
                popupType : 'update_res',
                updateResource : updateResource,
            });
        }
    };
    handleClick = (clickEvent,task_id = 0,rowData={}) => {
        let formValues = this.state.formValues;
        formValues.start_date = DefaultStartDate;
        if (parseInt(task_id) === 0) {
            formValues.end_date = DefaultEndDate;
        } else {
            formValues.end_date = null;
        }
        if (clickEvent == 'add_task') {
            if (typeof formValues.type === 'undefined' || formValues.type === "") {
                formValues.type = '1';
            }
            if (typeof formValues.status === 'undefined' || formValues.status === "") {
                formValues.status = '1';
            }
        }
        let taskWithClin = false;
        if (typeof rowData.clins !== 'undefined' && rowData.clins !== "") {
            taskWithClin = true;
        }
        this.setState({
            dialogOpen : true,
            popupType : clickEvent,
            editId : task_id,
            moveTaskProjectId : 0,
            moveTaskId : task_id,
            formValues : formValues,
            endDateConfirmation : false,
            taskWithClin : taskWithClin
        }, function () {
            if (task_id > 0 && clickEvent !== 'delete_task' && clickEvent !== 'move_task') {
                this.getEditTaskDetails();
            }
        });
    }
    deleteTask = () => {
        let deleteId = this.state.editId;
        if (deleteId) {
            this.setState({showLoader: true});
            return httpServices
                .post("api/project/taskdelete", {id: deleteId})
                .then((response) => {
                    if (response.data && response.data.valid) {
                        let msg = "Task deleted successfully";
                        this.setState({
                            snackbarVariant : 'success',
                            snackbarOpen : true,
                            snackbarMsg : msg,
                            editId : 0,
                            dialogOpen : false,
                            popupType : '',
                            showLoader: false
                        }, function () {
                            this.getProjectTasks();
                        });
                    } else {
                        let msg = "Something went wron, please try again later";
                        if (response.data.err_msg) {
                            msg = response.data.err_msg;
                        }
                        this.setState({
                            snackbarVariant : 'error',
                            snackbarOpen : true,
                            snackbarMsg : msg,
                            editId : 0,
                            dialogOpen : false,
                            popupType : '',
                            showLoader: false
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formValues;
        if (evt.target.type && evt.target.type === 'checkbox') {
            ff[field] = evt.target.checked;
            this.MixpanelManager.trackEvent('Projects', {'module' : 'Projects', 'feature' : 'task', 'action' : 'send-notification-to-assignees-unchecked'});
        }
        else{
            ff[field] = evt.target.value;
        }
        let showTimeoffTypes = this.state.showTimeoffTypes;
        if (field === 'type') {
            showTimeoffTypes = (evt.target.value == '3') ? true : false;
        }
        this.setState({
            formValues: ff,
            showTimeoffTypes : showTimeoffTypes,
        });
    }
    handleDateChange = (date, name) => {
        let formValues = this.state.formValues;
        formValues[name] = (date !== "Invalid date" ? date : null);
        this.setState({
            formValues: formValues,
        });
    };
    onSelectResources = (selectedList, selectedItem) => {
        let formValues = this.state.formValues;
        formValues['resources'] = selectedList;
        this.setState({
            formValues : formValues,
        });
    }
    handleEndDateConfirmation = () => {
        if (!this.state.formValues.end_date || this.state.formValues.end_date === '' ) {
            let errors = this.state.errors;
            errors['end_date'] = 'End date cannot be empty';
            this.setState({
                errors : errors,
            });
        } else {
            this.setState({
                endDateConfirmation : true,
            }, function(){
                this.handleSubmit();
            });
        }
    }
    handleSubmit = () => {
        let valid = this.validateTaskData();
        if (typeof this.state.formValues.status !== 'undefined' && this.state.formValues.status == '3' && !this.state.endDateConfirmation && (!this.state.formValues.end_date || this.state.formValues.end_date == '')) {
            this.setState({
                popupType : 'end_date_confirmation',
                dialogOpen : true
            });
            valid = false
        } 
        if (valid) {
            this.setState({
                showLoader : true,
            });

            let tempData = this.state.formValues;
            let data = {};
            if (tempData) {
                data = JSON.stringify(tempData);
                data = JSON.parse(data);
            }
            let resources = data.resources;
            let resources_arr = [];
            if (resources && typeof resources !== 'undefined' && resources.length) {
                resources.forEach(function (res) {
                    if (res.user_id) {
                        resources_arr.push(res.user_id);
                    }
                }); 
                data.resources = null;
            }
            if (data && data.clin && data.clin.length) {
                data.clin = JSON.stringify(data.clin);
            }
            if (typeof data.status === 'undefined') {
                data.status = '1';
            }
            if (typeof data.type === 'undefined') {
                data.type = '1';
            }
            data.task_users = JSON.stringify(resources_arr);
            data.tags = JSON.stringify(this.state.formValues.tags);
            data.project_id = this.state.projectId;
            if (!this.state.showTimeoffTypes) {
                data.timeoff_type = 0;
            }
            let api = "api/project/taskadd";
            if (this.state.editId) {
                api = "api/project/edittask";
                data.id = this.state.editId;
                data.action = 'update';
            }
            return httpServices
                .post(api, data)
                .then((response) => {
                    if (response.data['valid_data']) {
                        if (response.data['valid']) {
                            // Show success notification && clear form values
                            let msg = '';
                            if (this.state.editId) {
                                msg = "Task updated successfully";
                            } else {
                                msg = "Task created successfully";
                            }
                            this.setState({
                                dialogOpen : false,
                                popupType : '',
                                formValues : {},
                                tags : [],
                                editId : 0,
                                snackbarOpen : true,
                                snackbarVariant : 'success',
                                snackbarMsg : msg,
                                errors : {},
                                showLoader : false,
                            }, function () {
                                this.getProjectTasks(); // Update Dom
                            });
                        } else {
                            let errors = {};
                            if (response.data.err_msg) {
                                errors['post_validation_error'] = response.data.err_msg;
                                let formValues = this.state.formValues;
                                formValues.resources = resources;
                                this.setState({
                                    errors : errors,
                                    showLoader : false,
                                    formValues : formValues,
                                });
                            }
                        }
                }
                else
                {
                    this.setState({
                        errors: response.data.errors,
                        showLoader: false,
                    });
                }
                }) 
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    validateTaskData = () => {
        let errors = {};
        let formValues = this.state.formValues;
        let valid = true;
        if (!formValues.task_name || formValues.task_name === 'undefined' || formValues.task_name.trim() === '') {
            errors['task_name'] = 'Task cannot be empty';
            valid = false;
        }
        if (!formValues.task_description || typeof formValues.task_description === 'undefined' || formValues.task_description.trim() == '' ) {
            errors['task_description'] = 'Task Description cannot be empty';
            valid = false;
        }
        if (typeof formValues.type === 'undefined' || formValues.type === '') {
            errors['type'] = 'Type cannot be empty';
            valid = false;
        }
        if (typeof formValues.status === 'undefined' || formValues.status === '') {
            errors['status'] = 'Status cannot be empty';
            valid = false;
        }
        if (formValues.type && formValues.type == '3' && (!formValues.timeoff_type || formValues.timeoff_type == '')) {
            errors['timeoff_type'] = 'Time Off Type cannot be empty';
            valid = false;
        }
        if (!formValues.start_date || formValues.start_date === '' ) {
            errors['start_date'] = 'Start date cannot be empty';
            valid = false;
        }
        else if (formValues.end_date && (formValues.start_date > formValues.end_date)) {
            errors['start_date'] = 'Start date should be less than end date';
            valid = false;
        }
        let duplicates = []; 
        this.state.tags.forEach(function (value, index, array) {
        if (array.indexOf(value, index + 1) !== -1
            && duplicates.indexOf(value) === -1) {
            duplicates.push(value);
        }
        });
        if (duplicates.length > 0) {
            errors['tags'] = 'Duplicate labels are not allowed';
            valid = false;
        }
        this.setState({
            errors : errors,
        });
        return valid;
    }
    getEditTaskDetails = () => {
        let editId = this.state.editId;
        return httpServices
            .post("api/project/edittask", {id: editId, action : 'get'})
            .then((response) => {
                if (response.data.permission) {
                    let formValues = {};
                    let showTimeoffTypes = false;
                    if (response.data.task) {
                        formValues = response.data.task;
                        formValues.status = response.data.task.task_status;
                        formValues.type = response.data.task.task_type;
                        formValues.start_date = response.data.task.task_sdate;
                        formValues.end_date = response.data.task.task_edate ? response.data.task.task_edate : null;
                        formValues.timeoff_type = response.data.task.to_type ? response.data.task.to_type : '';
                        formValues.sent_notification = true;
                        formValues.tags = response.data.tags ? response.data.tags : [];
                        if (response.data.task.task_type && response.data.task.task_type == '3') {
                            showTimeoffTypes = true;
                        }
                    }
                    if (response.data.clin) {
                        formValues.clin = response.data.clin;
                    }
                    // let tags = [];
                    let taskResorces = [];
                    if (response.data.task_resources) {
                        let tempTResources = response.data.task_resources;
                        let allResources = (this.state.formInputs && this.state.formInputs.project_resources) ? this.state.formInputs.project_resources : [];
                        tempTResources.forEach (function (res) {
                            let resorceIndex = allResources.findIndex(x => x.user_id === res); 
                            if (resorceIndex !== -1) {
                                taskResorces.push(allResources[resorceIndex]);
                            }
                        });
                    }
                    formValues.resources = taskResorces;
                    this.setState({
                        formValues : formValues,
                        dialogOpen : true,
                        showTimeoffTypes : showTimeoffTypes,
                    });
                } else {
                    console.log("Permission error");
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    popupClose = () => {
        let updatedTasks = [];
        let formInputs = this.state.formInputs;
        if (this.state.formInputs && this.state.formInputs.tasks) {
            let tasks = this.state.formInputs.tasks;
            tasks.forEach(function (task){ 
                task['task_user_ids'] = task['task_user_ids_prev'];
                updatedTasks.push(task);
            });
            formInputs.tasks = updatedTasks;
        }
        
        this.setState({
            editId : 0,
            popupType : '',
            dialogOpen : false,
            tags : [],
            formValues : {},
            errors : {},
            moveTaskProjectId : 0,
            moveTaskId : 0,
            updateResource : {},
            formInputs : formInputs
        });
    }
    handleProjectChange = (currentNode) => (evt)=>{
        if (currentNode.project_id) {
            this.setState({
                moveTaskProjectId : currentNode.project_id,
            });
        }
    }
    moveTask = () => {
        let moveTaskProjectId = this.state.moveTaskProjectId;
        let moveTaskId = this.state.moveTaskId;
        if (moveTaskProjectId && moveTaskId) {
            this.setState({
                showLoader : true,
            });
            return httpServices
                .post("api/project/movetask", {project_id: moveTaskProjectId,task_id : moveTaskId})
                .then((response) => {
                    if (response.data && response.data.valid) {
                        this.setState({
                            moveTaskProjectId : 0,
                            moveTaskId : 0,
                            snackbarOpen : true,
                            dialogOpen : false,
                            popupType : '',
                            snackbarVariant : 'success',
                            snackbarMsg : "Task moved successfully",
                            showLoader : false,
                        }, function () {
                            this.getProjectTasks();                        
                        });
                    } else {
                        if (response.data.err_msg) {
                            this.setState({
                                moveTaskProjectId : 0,
                                moveTaskId : 0,
                                dialogOpen : false,
                                popupType : '',
                                snackbarOpen : true,
                                snackbarVariant : 'error',
                                snackbarMsg : response.data.err_msg,
                                showLoader : false,
                            });
                        }
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    handleFilterChange = (evt, value) => {
        let f = this.state.filterValues;
        let name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            filterValues: f
        });
    }
    handleResourceStatusFilterChange = (taskId, evt) => {
         let tasks = this.state.formInputs.tasks;
         let taskIndex = tasks.findIndex(x => x.task_id === taskId);
         this.state.formInputs.tasks[taskIndex]['task_assignee_status'] = evt.target.value;
         const data = this.state.filterValues;
         data.task_id = taskId;
         data.user_status = evt.target.value;
            return httpServices
                .post("api/project/gettaskresources", data)
                .then((response) => {
                    if (response.data) {
                        this.state.formInputs.tasks[taskIndex]['users'] = response.data.users;
                        this.setState({
                            showLoader : false,
                        });
                    } else {
                        this.setState({
                            permissionErr : true,
                            showLoader : false,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
    }
    resetFilter = () => {
        let f = {
            status : '1',
            type : '0'
        };
        this.setState({
            filterValues: f
        },() => {this.getProjectTasks();});
    }

    render(){
        return(
            <>
            <CustomProgressBar
                loaderOpen = {this.state.showLoader}
            />
            {this.state.permissionErr ? 
            <ErrorPage/>
            :
            <TaskComponent
                FormInputs = {this.state.formInputs}
                HandleResourceChange = {this.handleResourceChange}
                HandleTaskChange = {this.handleTaskChange}
                HandleResourceStatusFilterChange = {this.handleResourceStatusFilterChange}
                UpdateTaskResources = {this.updateTaskResources}
                HandleClick = {this.handleClick}
                DialogOpen = {this.state.dialogOpen}
                PopupType = {this.state.popupType}
                FormValues = {this.state.formValues}
                HandleOnChange = {this.handleOnChange}
                HandleDateChange = {this.handleDateChange}
                HandleTagAddition = {this.handleTagAddition}
                Tags = {this.state.tags}
                OnSelectResources = {this.onSelectResources}
                ShowTimeoffTypes = {this.state.showTimeoffTypes}
                Errors = {this.state.errors}
                HandleSubmit = {this.handleSubmit}
                EditId = {this.state.editId}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarVariant = {this.state.snackbarVariant}
                SnackbarMsg = {this.state.snackbarMsg}
                SnackbarClose = {this.snackbarClose}
                PopupClose = {this.popupClose}
                DeleteTask = {this.deleteTask}
                HandleProjectChange = {this.handleProjectChange}
                MoveTask = {this.moveTask}
                HandleEndDateConfirmation = {this.handleEndDateConfirmation}
                ShowLoader = {this.state.showLoader}
                toggleDrawer={this.toggleDrawer}
                Right = {this.state.right}
                FilterValues = {this.state.filterValues}
                FilterInputs={this.state.filterInputs}
                HandleFilterChange={this.handleFilterChange}
                SubmitFilter={this.getProjectTasks}
                ResetFilter={this.resetFilter}
                UpdateResource = {this.state.updateResource}
                HandleResourceEditClick = {this.handleResourceEditClick}
                TaskWithClin= {this.state.taskWithClin}
                NameFormat = {this.state.nameFormat}
                NameSortOrder = {this.state.nameSortOrder}
            />
            }
            </>
        )
    }
}
