import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import FilterListIcon from '@mui/icons-material/FilterList';
import { SubmitFilterComponent } from './SubmitFilterComponent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TablePagination from '@mui/material/TablePagination';

export const SubmitComponent = (props) => {
     const [page, setPage] = React.useState(0);
     const [rowsPerPage, setRowsPerPage] = React.useState(10);
     const handleChangePage = (event, newPage) => {
      setPage(newPage);
      };
     const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(+event.target.value);
     setPage(0);
     };

    
    return (
        <React.Fragment>
            {!props.permisionError && <div className={'section-main'}>
              <div className={'section-header'}>
                <div className={'col-left'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                            Expense Vouchers
                        </Typography>
                    </div>
                  <div className={'action'}>
                       <Tooltip title="Filter">
                                <IconButton
                                    onClick={props.FilterView('right', true)}
                                    >
                                    <FilterListIcon color="action" />
                                </IconButton>
                            </Tooltip>
                            <Drawer open={props.FilterViewState} anchor={'right'} onClose={props.FilterView('right', false)} >
                                <div className={'tree-view-drawer'}>
                                    <div className={'drawer-title'}>Filter</div>
                                    <SubmitFilterComponent ExpenseStatus = {props.ExpenseStatus} FilterFields = {props.FilterFields} startYear = {props.startYear} endYear ={props.endYear} handleOnSubmit = {props.FilterSubmit} HandleOnChange={props.HandleOnChange} HandleFilterDateChange={props.HandleFilterDateChange} errors = {props.errors}/>
                                </div>
                            </Drawer>

                    </div>
                   </div>
                <div className={'section-body'}>
            <Paper>
               <TableContainer component={Paper}>
                <Table  aria-label="Timesheet List" className={'list-table_basic steps-table'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Expense Period</TableCell>
                            <TableCell>Voucher ID</TableCell>
                            <TableCell>Purpose</TableCell> 
                            <TableCell className={'text-right'}>Submitted Amount</TableCell>
                            <TableCell className={'text-right'}>Actual Payment</TableCell>
                            <TableCell>Submitted On</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                         {(props.ExpenseReport.length === 0)  ?
                            <TableRow >
                                <TableCell>No expense vouchers found.</TableCell>
                            </TableRow>
                             :
                        <>
                        {props.ExpenseReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                        <TableRow>
                            <TableCell>{row.start_date} - {row.end_date}</TableCell>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.purpose}</TableCell>
                            <TableCell className={'text-right'}>{row.total_amount}</TableCell>
                            <TableCell className={'text-right'}>{row.actual_payment}</TableCell>
                            {(parseInt(row.submitted_on) !== 0) ? 
                            <TableCell>{row.submitted_on}</TableCell>
                            :
                             <TableCell></TableCell>
                            }
                            <TableCell className={row.colour_code}>{row.status_name}</TableCell>
                            <TableCell>
                                <Tooltip title="View" aria-label="view">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="View">
                                        <VisibilityIcon fontSize="small" onClick={()=>props.HandleViewClick(row.id)}/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        )}
                      {props.ExpenseReport.length &&
                       <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Total Amount</TableCell>
                            <TableCell className={'text-right'}>{props.submittedAmountTotal}</TableCell>
                            <TableCell className={'text-right'}>{props.actualPaymentTotal}</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                     }
                    </>
                  }
                        
                     
                    </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            </div>
        </div>}
        <p className='error' align="center">{props.permisionError}</p>
        {!props.permisionError && <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={props.ExpenseReport.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />}
      </React.Fragment>    
    )
};
