import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { CustomDatePicker } from '../../common/CustomDatePicker';

export const SubmitFilterComponent = (props) => {console.log(props);
    return (
        <form className="form-fullwidth">
            <Grid container spacing={2}>
            <Grid item xs={9}>
            <FormControl>
                <CustomDatePicker
                    id = "startDate"
                    name = "startDate"
                    selectedDate = {props.FilterFields.startDate}
                    handleDateChange = {props.HandleFilterDateChange}
                    label = {'Start Date'}
                />
        </ FormControl>
        </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={9}>
            <FormControl>
                <CustomDatePicker
                    id = "endDate"
                    name = "endDate"
                    selectedDate = {props.FilterFields.endDate}
                    handleDateChange = {props.HandleFilterDateChange}
                    label = {'End Date'}
                />
                <span class="error">{props.errors.endDate}</span>
            </ FormControl>
        </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={9}>
            <FormControl variant="standard">
            <InputLabel htmlFor="age-simple">Status</InputLabel>
            <Select
                inputProps={{
                    name: 'status',
                    id:   'status',
                }}
               value = {(typeof props.FilterFields.status !== 'undefined') ? props.FilterFields.status  : 0}
               onChange = {props.HandleOnChange}
            >
                <MenuItem value="0">All</MenuItem>
                <MenuItem value="-1">To be submitted</MenuItem>
                {typeof(props.ExpenseStatus) !== 'undefined' && (props.ExpenseStatus != null)  && props.ExpenseStatus.map((t, i) =>
                    <MenuItem value={t.id} key={'c-'+i}>{t.status}</MenuItem>
                )}
            </Select>
        </FormControl>
        </Grid>
        </Grid>
        <Button variant="contained" color="primary" onClick={props.handleOnSubmit}> Submit </Button>
        </form>
    )
}
