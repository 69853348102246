import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';  // used 0.04kb
import Button from '@mui/material/Button';
import * as _ts from '../common/treeSelect';
import Tooltip from '@mui/material/Tooltip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


export const ActionDetailsComponent = (props) => {
    const roles = (typeof props.Roles !== 'undefined') ? Object.values(props.Roles) : [];
    return (
        <div>
            <div className={'section-main section-main-header-fixed'}>
                <div className={'header-fixed'} >
                    <div className={'section-header'}>
                        <div className={'col-left'}>
                            <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                            <Typography variant="h6" component="h6" className={'section-title'}>
                                {(_R.type(props.ActionDetails.action) !== 'Undefined') &&
                                    <>
                                        {props.ActionDetails.action}
                                    </>
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'section-body'}>
                    <Paper className={'mb-1'}>
                        <div className={'p-1rem summary box-details'}>
                            <ul className={'list-standard'}>
                                <li className={'list-item'}>
                                        <label>Module</label>
                                        <span className={'value'}>{props.ActionDetails.module}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Action</label>
                                        <span className={'value'}>{props.ActionDetails.action}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Description</label>
                                        <span className={'value'}>{props.ActionDetails.description}</span>
                                    </li>
                            </ul>
                        </div> 
                    </Paper>
                    <Paper>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Role Name</TableCell>
                                    <TableCell>Role Type</TableCell>
                                    <TableCell>Slug</TableCell>
                                    <TableCell>Is Default</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {roles.map((r,i) =>
                                    <TableRow hover>
                                        <TableCell><a className={'item-link'} onClick={props.ViewRoleDetails(r.role_id)}>{r.name}</a></TableCell>
                                        <TableCell>{r.role_type}</TableCell>
                                        <TableCell>{r.role_slug}</TableCell>
                                        <TableCell>{r.is_default == 1 ? "Yes" : "No"}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
            </div>
        </div>
    );
}