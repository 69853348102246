import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import parse from 'html-react-parser';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Badge from '@mui/material/Badge';
import {httpServices}  from '../../../services/httpServices'
import { formatName } from '../../common/common';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { TimeEntryEdit } from '../timeEntry/TimeEntryEdit';
import * as _R from 'ramda';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import {DialogBoxLarge} from '../../common/dialog.large';
import Typography from '@mui/material/Typography';
import { Comment } from '../../reports/Common/Notes';
import { ChangeHistoryReport } from '../../reports/Common/ChangeHistory';
import LockIcon from '@mui/icons-material/Lock';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '& .closeButton': {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export class TimeSheetView extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            timesheet_details : [],
            gtotal : 0.00,
        };
    }
    componentDidMount = () => {
        if (this.props.TimesheetId && this.props.TimesheetId > 0) {
            this.index(this.props.TimesheetId, this.props.RequestedPage);
        } 
    }
    componentDidUpdate(prevProps) {
        if ((prevProps.TimesheetId !== this.props.TimesheetId) || (prevProps.ForceRerender !== this.props.ForceRerender)) {
            this.index(this.props.TimesheetId, this.props.RequestedPage);
        } 
    }
    index = (timesheet_id, requested_page) => {
        httpServices.post('api/timesheet/get-saved-timeslices', { timesheet_id : timesheet_id, requested_page : requested_page })
            .then(response => {
                if(response.data){
                    if (response.data) {
                        if (response.data.timeslices) {
                            this.prepareTimeSliceData(response.data.timeslices);
                        }
                    }
                }
            })
            .catch(function(error) {
                console.log("error" + error);
            });

    }
    
    prepareTimeSliceData = (data) => {
        let timesheet_details = [];
        let week_total = 0.0;
        let ts_count = Object.keys(data).length;
        let gtotal = 0.00;
        let date_array = Object.keys(data);
        let _this = this;
        let monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        Object.keys(data).map(function(key,p_index) {
            let obj = {};
            obj['date'] = key;
            obj['m_date'] = '';
            let day_total = 0.0;
            let project_name = '';
            let project_hours = 0.0; 
            let project_id = 0;
            let row_span_count = 0;
            let ts_details = data[key];
            obj['ts_details'] = [];
            if (ts_details && Array.isArray(ts_details) && ts_details.length > 0) {
                obj['ts_details'] = [];
                let p_details_arr = [];
                ts_details = ts_details.sort((a, b) => (a.project_id > b.project_id) ? 1 : -1)
                ts_details.forEach((ts,index) => {
                    // Check non confirmity
                    if (_this.props.ViewType === 'review') {
                        if ((_this.props.ShowReviewButton && parseInt(ts.non_confirmity) === 0) && 
                            ((!_this.props.MultipleReview) || ((_this.props.MultipleReview) && (_this.props.ProjectsByReviewer.map(Number).indexOf(parseInt(ts.project_id)) !== -1))))
                        {
                            ts.show_non_confirmity_chk_box = true;
                            ts.show_review_notes = true;
                        } else {
                            ts.show_non_confirmity_chk_box = false;
                            ts.show_review_notes = false;
                        }
                    }
                    if (index === 0) {
                        obj['m_date'] = ts.timeslice_date;
                        let monthDate = new Date(obj['m_date']+"T00:00:00");
                        obj['monthName'] = monthNames[monthDate.getMonth()]
                        obj['dateValue'] = monthDate.getDate();
                        obj['dateYear'] =  monthDate.getFullYear(); 
                        obj['dayName'] = days[monthDate.getDay()];
                    }
                    // Finding the project wise total
                    if (project_id > 0) {
                        if (parseInt(ts.project_id) === project_id) {
                            p_details_arr.push(ts);
                            project_hours = project_hours + parseFloat(ts.timeslice_hours);
                        } else {
                            let prj_details_obj = {};
                            prj_details_obj['p_details'] = p_details_arr;
                            prj_details_obj['p_name'] = project_name;
                            prj_details_obj['p_total'] = project_hours;
                            obj['ts_details'].push(prj_details_obj);
                            row_span_count = row_span_count + p_details_arr.length + 1;
                            project_name = '';
                            project_hours = 0.0;
                            p_details_arr = [];
                            p_details_arr.push(ts);
                            project_name = ts.project_name;
                            project_hours = project_hours + parseFloat(ts.timeslice_hours);
                        } 
                    } else {
                        p_details_arr.push(ts);
                        project_name = ts.project_name;
                        project_hours = project_hours + parseFloat(ts.timeslice_hours);
                    }
                    let next_elem = ts_details[index + 1];
                    if (typeof next_elem === 'undefined') {
                        let prj_details_obj = {};
                        prj_details_obj['p_details'] = p_details_arr;
                        prj_details_obj['p_name'] = project_name;
                        prj_details_obj['p_total'] = project_hours;
                        row_span_count = row_span_count + p_details_arr.length + 1;
                        obj['ts_details'].push(prj_details_obj);
                    }
                    if (ts.project_id) {
                        project_id = parseInt(ts.project_id);
                    }
                    // Calculating day total, grand total and weekly total
                    if(ts.timeslice_hours){
                        day_total = day_total + parseFloat(ts.timeslice_hours);  
                        week_total = week_total + parseFloat(ts.timeslice_hours);
                        gtotal = gtotal + parseFloat(ts.timeslice_hours);
                    }
                });
            } else if (typeof ts_details === 'object') {
                obj['m_date'] = ts_details.modified_date;
                let monthDate = new Date(obj['m_date']+"T00:00:00");
                obj['monthName'] = monthNames[monthDate.getMonth()]
                obj['dateValue'] = monthDate.getDate();
                obj['dateYear'] = monthDate.getFullYear();
                obj['dayName'] = days[monthDate.getDay()];
            }
            obj['day_total'] = day_total;
            obj['row_span_count'] = row_span_count;
            timesheet_details.push(obj);
            let wk_end_summary = {};
            wk_end_summary['weekend_flag'] = true;
            wk_end_summary['weekend_total'] = week_total;
            //let next_index = p_index+1;
            let next_date = new Date(date_array[p_index+1]+"T00:00:00");
            var weekend_date = new Date(key+"T00:00:00");
            weekend_date.setDate(weekend_date.getDate() + (7 + 6 - weekend_date.getDay()) % 7);
            if ((p_index + 1) === ts_count) {
                timesheet_details.push(wk_end_summary);
                week_total = 0.0;
            }
            else if (next_date > weekend_date) {
                timesheet_details.push(wk_end_summary);
                week_total = 0.0;
            }
            return false;
        });
        this.setState({
            timesheet_details : timesheet_details,
            gtotal : gtotal,
        });
    }
    render (){
    return (
        <>
            <Paper className={'mb-1'}>
                <div className={'timeSheet-list_body'}>
                    <Table className={'list-table_basic'} size="small">
                        <TableHead className={'thead'}>
                            <TableRow>
                                <TableCell width="50px">Date</TableCell>
                                {(parseInt(this.props.UserTimeIn) === 1 && _R.type(this.props.UserTimeInDetails) !== 'Undefined') &&
                                <TableCell width="20%">Time in/out</TableCell>
                                }
                                <TableCell width="20%"><div>Charge code</div>
                                <div>Client / Project </div></TableCell>
                                <TableCell width="20%">Task</TableCell>
                                <TableCell width="60%">Description</TableCell>
                                <TableCell>Hours</TableCell>
                                <TableCell className={'action'}>Last modified</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={'tbody'}>
                            {this.state.timesheet_details.map( (row, row_index ) =>
                            <>
                                {(_R.type(row.ts_details) !== 'Undefined' && row.ts_details.length > 0) && row.ts_details.map((ts,ts_index)=>
                                <>
                                    {(_R.type(ts.p_details) !== 'Undefined' && ts.p_details.length > 0) && ts.p_details.map((p,p_index)=>
                                        <TableRow>
                                            {(ts_index === 0 && p_index === 0) && 
                                                <TableCell  rowSpan={row.row_span_count} component="th" scope="row" className={'rowboarder-right vtop th-date'}>
                                                    <span className={'timesheet-list_row-date'}>
                                                        <span className={'dm'}>
                                                            <span className={'dd'}>{row.dayName ? row.dayName.toUpperCase() : ''}</span>
                                                        </span>
                                                        {row.monthName ? row.monthName : ''}
                                                        <span className={'dm'}>
                                                            <span className={'dd'}>{row.dateValue ? row.dateValue : ''}</span>
                                                        </span>
                                                        {row.dateYear ? row.dateYear : ''}
                                                    </span>
                                                </TableCell>
                                            }
                                            {(ts_index === 0 && p_index === 0 && parseInt(this.props.UserTimeIn) === 1 && _R.type(this.props.UserTimeInDetails) !== 'Undefined') && 
                                                <TableCell  rowSpan={row.row_span_count} className={parseInt(p.non_confirmity) ? 'rowboarder-right timesheet-viewpage-error' : 'rowboarder-right'}>
                                                    {this.props.UserTimeInDetails[row.date] && 
                                                        this.props.UserTimeInDetails[row.date].map( ( t_in_outrow ) =>
                                                            <div>{t_in_outrow.time_in_ampm} - {t_in_outrow.time_out_ampm} 
                                                            <b> {t_in_outrow.timezone_slug}</b>

                                                                <>
                                                                    {t_in_outrow.location_color_code ?
                                                                        <span className='tasktype-badge wh' style={{ backgroundColor: t_in_outrow.location_color_code }}>
                                                                            {t_in_outrow.location_name}
                                                                        </span>
                                                                        :
                                                                        t_in_outrow.location_name ? 
                                                                        <span className='tasktype-badge wh'>
                                                                            {t_in_outrow.location_name}
                                                                        </span>
                                                                        :
                                                                        <span></span>
                                                                    }
                                                                </>
                                                            </div>
                                                        )                                    
                                                    }
                                                </TableCell>
                                            }
                                            <TableCell className={parseInt(p.non_confirmity) ? 'timesheet-viewpage-error' :''}>
                                                <div className={parseInt(p.non_confirmity) ? 'te-charge-code timesheet-viewpage-error' : 'te-charge-code'}>{p.chargecode}</div>
                                                <div> {this.props.ClientsCount < 2 ? p.project_path : p.client_code+' / '+p.project_path}</div>
                                            </TableCell>
                                            <TableCell  className={parseInt(p.non_confirmity) ? 'timesheet-viewpage-error' : ''}>{ p.task_name } 
                                            {(p.location_name && p.enable_location == true)&&
                                                <>
                                                {p.location_color_code ?
                                                    <span className='tasktype-badge wh'  style={{ backgroundColor: p.location_color_code }}>
                                                        {p.location_name}
                                                    </span>
                                                    :
                                                    p.location_name ?
                                                    <span className='tasktype-badge wh'>
                                                        {p.location_name}
                                                    </span>
                                                    :
                                                    <span></span>
                                                }
                                                </>
                                            }
                                            </TableCell>
                                            <TableCell width="30%">
                                                <div className={parseInt(p.non_confirmity) ? 'timesheet-list_row-description timesheet-viewpage-error' : 'timesheet-list_row-description'}>    
                                                    {parse(p.timeslice_log)}
                                                </div>
                                                <div className={'timesheet-list_row-tags'}>
                                                    {_R.type(p.tags) !== 'Undefined' && p.tags.length > 0 && Object.values(p.tags).map((tag) =>
                                                        <span className={'tag'}>{tag.tag_name}</span>
                                                    )}
                                                </div>
                                            </TableCell>
                                            <TableCell className={parseInt(p.non_confirmity) ? 'timesheet-viewpage-error' :''}><span className={'timesheet-list_row-time col-time'}>{p.timeslice_hours}</span>
                                                <div>
                                                 <Tooltip title={p.tasktype_name} aria-label="tasktype">
                                                    <span className={'tasktype-badge '+p.code.toLowerCase()}>{p.code}</span>
                                                </Tooltip>
                                                </div>
                                            </TableCell>
                                            <TableCell className={'td-action'}>
                                                <div  className="action">
                                                    <>
                                                    {(this.props.ViewType === 'submit') &&
                                                        <div className={parseInt(p.non_confirmity) ? 'timesheet-list_row-description timesheet-viewpage-error' : 'timesheet-list_row-description'}><span>{p.modifiedDateValue}</span><span>{p.modifiedTimeValue}</span></div> }
                                                        <div className="action-btn">
                                                        {(this.props.ViewType === 'submit') &&
                                                            <>
                                                        {(p.can_edit  &&  p.edit) ?
                                                        <>
                                                            <Tooltip title="Review Notes" aria-label="review_notes">
                                                                {parseInt(p.note_count) ?
                                                                <Badge color="secondary" variant="dot" className={'action_icon visible'}>
                                                                    <AssignmentOutlinedIcon fontSize="small" aria-haspopup="true" onClick={() => this.props.listReviewNotes(p.timeslice_id)} />
                                                                </Badge>
                                                                :
                                                                    <AssignmentOutlinedIcon fontSize="small" className={'action_icon'} aria-haspopup="true" onClick={() => this.props.listReviewNotes(p.timeslice_id)} />
                                                                }
                                                            </Tooltip>
                                                            {(p.sync_timeslice_id == null) &&
                                                                <>
                                                                {(!p.locked) ?
                                                                    <>
                                                                    <Tooltip title="Edit" aria-label="edit">
                                                                        <IconButton edge="end" aria-label="Edit" className={'action_icon'}>
                                                                            <EditIcon id={p.timeslice_id} aria-haspopup="true" onClick={() => this.props.onTimeEntryEditClick(p.timeslice_id)} fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete" aria-label="delete">
                                                                        <IconButton edge="end" aria-label="Delete" className={'action_icon'}>
                                                                            <DeleteIcon fontSize="small" onClick={() => this.props.onTimeEntryDeleteClick(p.timeslice_id)} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    </>
                                                                    :
                                                                    <>
                                                                    <div className='timesheet-list_row-locked'>
                                                                        <IconButton size="small" className={'action-btn'} edge="end">
                                                                            <LockIcon aria-haspopup="true" onClick={() => this.props.GetLockedDetails(p.lock_created_by, p.locked_on)}>
                                                                            </LockIcon>
                                                                        </IconButton>
                                                                    </div>
                                                                    </>
                                                                    }
                                                                </>
                                                            }
                                                            {p.sync_timeslice_id && p.locked &&
                                                                <>
                                                                    <div className='timesheet-list_row-locked'>
                                                                        <IconButton size="small" className={'action-btn'} edge="end">
                                                                            <LockIcon aria-haspopup="true" onClick={() => this.props.GetLockedDetails(p.lock_created_by, p.locked_on)}>
                                                                            </LockIcon>
                                                                        </IconButton>
                                                                    </div>
                                                                </>
                                                            }
                                                            {(p.change) &&
                                                            <Tooltip title="Change" aria-label="Change">
                                                                <IconButton   edge="end" aria-label="Delete" className={'action_icon'}>
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            }
                                                        </>
                                                        : 
                                                        <>
                                                            <Tooltip title="Review Notes" aria-label="review_notes">
                                                                {parseInt(p.note_count) ?
                                                                <Badge color="secondary" variant="dot" className={'action_icon visible'}>
                                                                    <AssignmentOutlinedIcon fontSize="small" aria-haspopup="true" onClick={() => this.props.listReviewNotes(p.timeslice_id)} />
                                                                </Badge>
                                                                :
                                                                <AssignmentOutlinedIcon fontSize="small" className={'action_icon'} aria-haspopup="true" onClick={() => this.props.listReviewNotes(p.timeslice_id)} />
                                                                }
                                                            </Tooltip>
                                                        </>
                                                        }</>
                                                    } </div>
                                                    {(this.props.ViewType === 'review') &&
                                                    <>
                                                        <div className={parseInt(p.non_confirmity) ? 'timesheet-list_row-description timesheet-viewpage-error' : 'timesheet-list_row-description'}><span>{p.modifiedDateValue}</span><span>{p.modifiedTimeValue}</span></div>
                                                            <div className="action-btn">
                                                                {p.show_review_notes &&
                                                                    <Tooltip title="Review Notes" aria-label="review_notes">
                                                                        {parseInt(p.note_count) ?
                                                                            <Badge color="secondary" variant="dot" className={'action_icon visible'}>
                                                                                <AssignmentOutlinedIcon fontSize="small" aria-haspopup="true" onClick={() => this.props.listReviewNotes(p.timeslice_id)} />
                                                                            </Badge>
                                                                            :
                                                                            <AssignmentOutlinedIcon fontSize="small" className={'action_icon'} aria-haspopup="true" onClick={() => this.props.listReviewNotes(p.timeslice_id)} />
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                        {/*Non confirmity START*/}
                                                        {p.show_non_confirmity_chk_box && 
                                                        <Checkbox
                                                            className={(parseInt(p.non_confirmity) || this.props.NonConfirmity.map(Number).indexOf(parseInt(p.timeslice_id)) > -1) ? 'action_icon visible' : 'action_icon'}
                                                            onChange={this.props.HandleNonConfirmityChange(p_index,p.timeslice_id)}
                                                            value={p.non_confirmity}
                                                            color="primary"
                                                        />
                                                        } 
                                                        </div>
                                                        {/*Non confirmity END*/}
                                                    </>
                                                    }
                                                    {(this.props.ViewType === 'payPeriodStatusReport') &&
                                                    <>
                                                        <div className={parseInt(p.non_confirmity) ? 'timesheet-list_row-description timesheet-viewpage-error' : 'timesheet-list_row-description'}><span>{p.modifiedDateValue}</span><span>{p.modifiedTimeValue}</span></div>
                                                        <div className="action-btn">
                                                        <Tooltip title="Details" aria-label="Details">
                                                            <AssignmentOutlinedIcon fontSize="small" className={'action_icon'} aria-haspopup="true" onClick={() => this.props.OnDetailsClick(p.timeslice_id)} />

                                                        </Tooltip>
                                                        </div>
                                                    </>
                                                    }
                                                 </>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={'timesheet-list_row-project-total'}>
                                        <TableCell align="right" colSpan={3}><span className={'info'}>{ts.p_name}</span></TableCell>
                                        <TableCell colSpan={2}>
                                            <span className={'timesheet-list_row-time'}><span className={'col-time'}>{ts.p_total.toFixed(2)}</span></span>
                                        </TableCell>
                                    </TableRow>
                                </>
                                )}
                                {(((_R.type(row.ts_details)) === 'Undefined' || row.ts_details.length < 1) && (this.props.DayOff[new Date(row.date+"T00:00:00").getDay()] === 'Y') ) &&
                                    <TableRow>
                                        <TableCell className={'vtop th-date'}align="left">
                                            <span className={'timesheet-list_row-date'}>
                                                <span className={'dm'}>
                                                    <span className={'dd'}>{row.dayName ? row.dayName.toUpperCase() : ''}</span>
                                                </span>
                                                {row.monthName ? row.monthName : ''}
                                                <span className={'dm'}>
                                                    <span className={'dd'}>{row.dateValue ? row.dateValue : ''}</span>
                                                </span>
                                                {row.dateYear ? row.dateYear : ''}
                                            </span>
                                        </TableCell>
                                        <TableCell colSpan={9} align="left">No hours saved</TableCell>
                                    </TableRow>
                                }
                                {(_R.type(row.ts_details)) !== 'Undefined' && row.ts_details.length > 0 &&
                                    <TableRow className={'timesheet-list_row-daytotal shaded'}> 
                                        {((parseInt(this.props.UserTimeIn) === 1 && _R.type(this.props.TimeinTotal) !== 'Undefined' && this.props.TimeinTotal[row.date]) ?
                                            <TableCell  colSpan={(parseInt(this.props.UserTimeIn) === 1) ? 5 : 4}>
                                                <span className={'timesheet-list_row-time'}>
                                                    <span className={'info'}>Time in/out Total: </span></span>
                                                    <span className={'col-time'}>{this.props.TimeinTotal[row.date].diff_hours} Hours</span>
                                            </TableCell>
                                            :
                                            <TableCell colSpan={(parseInt(this.props.UserTimeIn) === 1) ? 5 :4}></TableCell>
                                        )}
                                    {/*<TableCell colSpan={(parseInt(this.props.UserTimeIn) === 1 && _R.type(this.props.UserTimeInDetails) !== 'Undefined') ? 3 : 1 }></TableCell> */}
                                        <TableCell colSpan={2}>
                                                <span className={'timesheet-list_row-time'}><span className={'col-time'}>{row.day_total.toFixed(2)}</span> <span className={'info'}>Day total</span></span>
                                        </TableCell>
                                    </TableRow>
                                }
                                {(_R.type(row.weekend_flag) !== 'Undefined' && row.weekend_flag && row.weekend_total > 0) &&
                                    <TableRow className={'timesheet-list_row-weektotal shaded'}>
                                        <TableCell align="right" colSpan={(parseInt(this.props.UserTimeIn) === 1 && _R.type(this.props.UserTimeInDetails) !== 'Undefined') ? 5 : 4 }></TableCell>
                                        <TableCell colSpan={2}>
                                                <span className={'timesheet-list_row-time'}><span className={'col-time'}>{row.weekend_total.toFixed(2)}</span> <span className={'info'}>Weekly total</span></span>
                                        </TableCell>
                                    </TableRow>
                                }
                            </>
                            )}
                        </TableBody>
                    </Table>
                
                </div>
            </Paper>
            <TimeEntryEdit 
                timeSliceId={this.props.clickedTimeSliceId}
                Open= { this.props.timeSliceEditDialogOpen }  
                Cancel = { this.props.timeSliceEditDialogClose }
                timeSheetId = {this.props.timeSheetId}
                getTimesheetDetails = {this.props.getTimesheetDetails}
                randomNumber = {this.props.randomNumber}
                showEditSuccess = {this.props.ShowEditSuccess}
            />
            {this.props.PopupType === 'tag-delete' &&
            <DialogBoxMedium
                Open={this.props.DialogOpen}
                Cancel={this.props.OnClose}
                ButtonName={this.props.DialogButton}
                OnSubmit={this.props.OnTagDeleteConfirmed}
                Title='Confirm Delete'
                Content={
                    <DialogWithConfirmMessage msg="Do you really want to delete this tag ?" />
                }
            />
            }
            {this.props.PopupType === 'edit-error-msg' &&
            <DialogBoxMedium
                Open={this.props.DialogOpen}
                Cancel={this.props.OnClose}
                ButtonName={"OK"}
                OnSubmit={this.props.OnClose}
                Title='Time entry edit failed'
                Content={
                    <DialogWithConfirmMessage msg={this.props.editErrorMsg} />
                }
            />
            }
            {this.props.PopupType === 'show-review-list' &&
                    <ReviewList 
                        ReviewComments = {this.props.ReviewNotes}
                        ViewType = {this.props.ViewType}
                        ReviewNote = {this.props.ReviewNote}
                        HandleReviewNoteChange = {this.props.HandleReviewNoteChange}
                        AddReviewNote = {this.props.AddReviewNote}
                        Open = {this.props.DialogOpen}
                        ReviewNoteError = {this.props.ReviewNoteError}
                        Cancel={this.props.OnClose}
                        Title='Review Notes'
                    />
            }
            {this.props.PopupType === 'timeslice-delete' &&
            <DialogBoxMedium
                Open={this.props.DialogOpen}
                Cancel={this.props.OnClose}
                ButtonName={this.props.DialogButton}
                OnSubmit={this.props.TimesliceDelete}
                Loading = {this.props.ButtonLoader}
                Title='Confirm Delete'
                Content={
                    <ConfirmTimeSliceDelete props={this.props}/>
                }
            />
            }
            {this.props.PopupType === 'timeslice-report-view' &&
            <DialogBoxLarge
                Open={this.props.DialogOpen}
                Cancel={this.props.OnClose}
                HideSaveButton={true}
                Title='Time entry - Versions and Comments'
                Content={ <TimesliceReport props={this.props.TimeSliceDataView} /> }
            />
            }
            {this.props.PopupType === 'show-locked-details' && 
            <DialogBoxMedium
                Open={this.props.LockOpen}
                Cancel={this.props.OnClose}
                HideSaveButton={true}
                Title='Warning'
                OnSubmit={this.props.OnClose}
                Content={
                    this.props.PopupMsg
                }
            />
            }
       </> 
    );
    }
}
const TimesliceReport = (props) => {
    return (
        <React.Fragment>
            <div className={'section-main'}>
                <div>
                    <Paper className={'mb-1'}>
                        <Typography component="h2" className={'section-title mt-2'} variant="button" display="block" gutterBottom color="primary">
                            Current version
                        </Typography>
                        <div className={'timesheet_list-summary'}>
                            <div className={'p-1rem summary box-details'}>
                                <ul className={'list-standard'}>
                                    <li className={'list-item list-item-status'}>
                                        <label>Date</label>
                                        <span className={'value'}>{props.props.data.timeslice_date}</span>
                                    </li>
                                    <li className={'list-item list-item-status'}>
                                        <label>Client</label>
                                        <span className={'value'}>{props.props.data.client_name}</span>
                                    </li>
                                    <li className={'list-item list-item-status'}>
                                        <label>Task</label>
                                        <span className={'value'}>{props.props.data.task_name}</span>
                                    </li>
                                    <li className={'list-item list-item-status'}>
                                        <label>Hours</label>
                                        <span className={'value'}>{props.props.data.timeslice_hours}</span>
                                    </li>
                                    <li className={'list-item list-item-status'}>
                                        <label>Created On</label>
                                        <span className={'value'}>{props.props.data.timeslice_cdate}</span>
                                    </li>
                                    <li className={'list-item list-item-status'}>
                                        <label>Modified On</label>
                                        <span className={'value'}>{props.props.data.timeslice_mdate}</span>
                                    </li>
                                </ul>
                                <ul className={'list-standard'}>
                                    <li className={'list-item list-item-status'}>
                                        <label>Task Type</label>
                                        <span className={'value'}>{props.props.data.tasktype}</span>
                                    </li>
                                    <li className={'list-item list-item-status'}>
                                        <label>Project</label>
                                        <span className={'value'}>{props.props.data.project_name}</span>
                                    </li>
                                    <li className={'list-item list-item-status'}>
                                        <label>Description</label>
                                        <span className={'value'}>{props.props.data.timeslice_log}</span>
                                    </li>
                                    <li className={'list-item list-item-status'}>
                                        <label>Status</label>
                                        <span className={'value'}>{props.props.data.timesheet_status_name}</span>
                                    </li>
                                    <li className={'list-item list-item-status'}>
                                        <label>Offsite</label>
                                        <span className={'value'}>{props.props.data.offsite === '0' ? 'No' : 'Yes'}</span>
                                    </li>
                                    <li className={'list-item list-item-status'}>
                                        <label>Modified By</label>
                                        <span className={'value'}>{props.props.data.modifiedby}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                </Paper>
                </div>
                <div>
                    <Comment Comments = {_R.type(props.props.comments) !== 'Undefined' ? props.props.comments : []} />
                    <ChangeHistoryReport History = {_R.type(props.props.history) !== 'Undefined' ? props.props.history : []} />
                </div>
                <div> 
                </div>
            </div>
        </React.Fragment>
    )
}

const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}

const ConfirmTimeSliceDelete = (p) => {
    const props = p.props;
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <span>Do you really want to delete this time entry ?</span>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="reason"
                        name="reason"
                        label="Reason"
                        multiline
                        variant="outlined"
                        fullWidth
                        onChange={props.HandleTimeEntryDeleteReason}
                        value={props.TimeEntryDeleteReason}
                        error={props.TimeEntryDeleteError && true}
                    />
                </Grid>
            </Grid>
        </>
    )
}

const ReviewList = (props) => {
    let cmnts = props.ReviewComments.length > 0 ? props.ReviewComments : [];
    return (
        <Dialog
            className={'modal_default'}
            open={props.Open}
            aria-labelledby="scroll-dialog-title"
        >
            <DialogTitle id="scroll-dialog-title" className={'dialogTitle'}>{props.Title}
                <StyledIconButton aria-label="close" className={'closeButton'} onClick={props.Cancel}>
                    <CloseIcon fontSize="small"/>
                </StyledIconButton>
            </DialogTitle>

                <DialogContent>
                    <>{(props.ViewType === 'review') &&
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="addnote"
                                    name="addnote"
                                    multiline
                                    variant="outlined"
                                    fullWidth
                                    onChange={props.HandleReviewNoteChange}
                                    value={props.ReviewNote}
                                    error={props.ReviewNoteError && true}
                                    helperText = {typeof props.ReviewNoteError !== 'undefined' ? props.ReviewNoteError : ''}
                                />
                                <Button className={'add_review_note_btn mt-1'} variant="contained" size="small" color="primary" onClick={props.AddReviewNote}>Add Note</Button>
                            </Grid>
                        </Grid>
                        }
                        {(cmnts && cmnts.length > 0) ? cmnts.map((c,i) =>
                        <>
                            <div class="notes">
                            <div class="note-item">
                                <div class="note-text">
                                {c.note}
                                </div>
                                <div class="notes-footer">
                                    <div class="notes-details">
                                        {formatName(c.user_first_name,null,c.user_last_name)} {c.comment_ctime}
                                    </div>
                                </div>
                            </div></div>
                        </>
                        )
                        :   
                        <>
                            <div class="notes">
                                <p>No notes for this time entry</p>
                            </div>
                        </>
                        }
            </>
        </DialogContent>
    </Dialog> 
    )
}


