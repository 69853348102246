import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { formatName } from '../common/common';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CustomizedSnackbars } from '../common/snackbars';
import {DialogBoxMedium} from '../common/dialog.medium';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const SystemRoleComponent = (props) => {   
    let tabIndex = "roles";
    //Tabs
    const [tabValue, setTabValue] = React.useState(tabIndex);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    const actions = (typeof props.Actions !== 'undefined') ? props.Actions : [];
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
        return (
            <React.Fragment>
                <div className={'section-main'}>
                    <div className={'section-body'}>
                        <Paper>
                            <div className={'tab-navContainer d-flex justify-between steps-tab'}>
                                <Tabs
                                    value={tabValue}
                                    onChange={tabChange}
                                    variant="scrollable"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="Actions and Roles"
                                    scrollButtons="auto"
                                >
                                    <Tab label="Actions" value="actions" />
                                    <Tab label="Roles" value="roles" />
                                </Tabs>
                            </div>
                            <div className={'tab-panelContainer'}>{ console.log(props.FilterModule) }
                                <TabPanel value={tabValue} index="actions">
                                    <form className="form-fullwidth role-details-modules-filter" >
                                        <Grid container spacing={3}>
                                        <Grid item xs={12} md={9}></Grid>
                                            <Grid item xs={12} md={2}>
                                            <InputLabel id="demo-simple-select-label">Modules</InputLabel>
                                                <FormControl variant="standard">{console.log(props.AllModules)}
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name={'filter_module'}
                                                        value={props.FilterModule}
                                                        onChange={props.HandleFilterChange}
                                                    >
                                                        <MenuItem value={'all'}>All Modules</MenuItem>
                                                        {_R.type(props.AllModules) !== 'Undefined' && props.AllModules.map((m, i) =>
                                                            <MenuItem value={m.module}>{m.module}</MenuItem>
                                                        )}

                                                    </Select>
                                                </ FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <InputLabel id="demo-simple-select-label"> </InputLabel>
                                                <Button className={'role-details-filter-btn'} variant="contained" size="small" color="primary" onClick={props.Filter}>Filter</Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                    <div className={'section-content roles-table'}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Action</TableCell>
                                                    <TableCell>Module</TableCell>
                                                    <TableCell>Description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.keys(actions).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                                                    <TableRow hover>
                                                        <TableCell>
                                                            <a className={'item-link'} onClick={props.ViewActionDetails(actions[row].action_id)}>{actions[row].action} </a>
                                                        </TableCell>
                                                        <TableCell>{actions[row].module}</TableCell>
                                                        <TableCell>{actions[row].description}</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[15, 30, 50]}
                                            component="div"
                                            count={actions.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel value={tabValue} index="roles">
                                    <div className={'tableSubHeader'}>
                                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"></Typography>
                                        <Tooltip title="Add Role" aria-label="add">
                                            <div className={'header-action'}>
                                                <Button variant="contained" color="primary" size="small" onClick={props.OnAddRoleClick} >Add</Button>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className={'section-content roles-table'}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Role Name</TableCell>
                                                    <TableCell>Role Type</TableCell>
                                                    <TableCell>Slug</TableCell>
                                                    <TableCell>Is Default</TableCell>
                                                    <TableCell>Created By</TableCell>
                                                    <TableCell>Created On</TableCell>
                                                    <TableCell>Modified By</TableCell>
                                                    <TableCell>Modified On</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.Roles.map((r,i) =>
                                                <TableRow hover>
                                                    <TableCell><a className={'item-link'} onClick={props.ViewRoleDetails(r.id)}>{r.name}</a></TableCell>
                                                    <TableCell>{r.role_type}</TableCell>
                                                    <TableCell>{r.role_slug}</TableCell>
                                                    <TableCell>{r.is_default == 1 ? "Yes" : "No"}</TableCell>
                                                    <TableCell>{formatName(r.cby_first_name, null, r.cby_last_name)}</TableCell>
                                                    <TableCell>{r.created_on}</TableCell>
                                                    <TableCell>{formatName(r.mby_first_name, null, r.mby_last_name)}</TableCell>
                                                    <TableCell>{r.modified_on}</TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title="View" aria-label="view">
                                                            <IconButton className={'action-btn'} edge="end" aria-label="View">
                                                                <VisibilityIcon fontSize="small" onClick={props.ViewRoleDetails(r.id)} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        {r.role_type !== "system_defined" &&
                                                            <>
                                                        <Tooltip title="Edit" aria-label="edit">
                                                            <IconButton className={'action-btn'} edge="end" aria-label="Edit">
                                                                <EditIcon fontSize="small" onClick={props.EditRoleClick(r.id)} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete" aria-label="delete">
                                                            <IconButton  className={'action-btn'} edge="end" aria-label="Delete" >
                                                                <DeleteIcon fontSize="small" onClick={props.DeleteRoleClick(r.id)}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                            </>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </TabPanel>
                            </div>
                        </Paper>
                    </div>
                </div>
                {props.PopupType === 'add' &&
                <DialogBoxMedium 
                    Open= { props.Open }  
                    Cancel = { props.OnClose }
                    Title = { props.IsEditable ? 'Edit Role' : 'Add Role' }
                    OnSubmit = { props.HandleSubmit }
                    Content = { <DialogContent FormProps = { props }/> }
                />
                }
                {props.PopupType === 'delete' &&
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    OnSubmit={props.DeleteRole}
                    Title='Confirm Delete'
                    Content={
                        <DialogWithConfirmMessage msg="Do you really want to delete this role ?" />
                    }
                />
                }
                <CustomizedSnackbars
                    open={props.SnackbarOpen}
                    variant={props.SnackbarType}
                    message={props.Message}
                    handleClose={props.SnackbarClose}
                />
            </React.Fragment>
        )
}

const DialogContent = (props) => {
    const { FormFields,HandleOnChange,Errors } = props.FormProps;
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "name"
                            name = "name"
                            label = "Name"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.name : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.name && true }
                            helperText = { Errors.name }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            id = "description"
                            name = "description"
                            label = "Description"
                            required
                            InputLabelProps =  {{
                                shrink: true,
                            }}
                            value = { FormFields.description }
                            onChange = { HandleOnChange }
                            error = { Errors.description && true }
                            helperText = { Errors.description }
                        />
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
