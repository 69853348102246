import React from 'react';
import { httpServices } from '../../../services/httpServices';
import { ProjectAddComponent } from './AddProject.Component.js';
import { CustomProgressBar } from '../../common/CustomProgressBar';

let formValues = {
    name : '',
    description : '',
    chargeCode : '',
    client : '',
    projectManager : '',
    startDate : '',
    endDate : '',
    projectStatus : '1',
    cor : '',
    minTimeInc : '',
    payType : '',
    offsite : false,
    lcr_required: false,
    approvalMethod : 'PM',
    notifyPm : true,
    reqLabourCategory : false,
    inheritResource : false,
    reqClientTimesheet : false,
    inheritReviewers : false,
    dateConfirmationError : '',
    snackbarOpen : false,
    snackbarMsg : '',
    snackbarVarient : '',
    timesheet_location : false,
    prelim_review_flag: false,
}

export class AddProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formInputs : {},
            formValues : formValues,
            showInheritResources : false,
            showInheritReviewers : false,
            errors : {},
            open : false,
            showSubProject : false,
            subProjects : [],
            parentSdate : '',
            parentEdate : '',
            validationPopupOpen : false,
            validationPopupMsg : '',
            popupType : '',
            permission : false,
            permissionErrMsg : '',
            getprojects : true,
            postValidationErr : '',
            taskCount : 0,
            prParent : 0,
            showLoader : true,
            buttonLoader:false,
            nameFormat : 'FIRSTLAST',
            prevInheritResource : false,
            inheritanceArray : []
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.clientChange = this.clientChange.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.parentChange = this.parentChange.bind(this);
        this.save = this.save.bind(this);
        this.createProject = this.createProject.bind(this);
        this.onClose = this.onClose.bind(this);
        this.getProjectDetails = this.getProjectDetails.bind(this);
        this.statusChange = this.statusChange.bind(this);
        this.editProject = this.editProject.bind(this);
        this.editProjectValidations = this.editProjectValidations.bind(this);
        this.confirmationClose = this.confirmationClose.bind(this);
        this.showTaskEndDateConfirmation = this.showTaskEndDateConfirmation.bind(this);
        this.validateDates = this.validateDates.bind(this);
        this.updateProject = this.updateProject.bind(this);
        this.confirmDatesSubmit = this.confirmDatesSubmit.bind(this);
        this.askEndDate = this.askEndDate.bind(this);
        this.taskEndDateConfirmation = this.taskEndDateConfirmation.bind(this);
        this.handleEndDateConfirmationForClosed = this.handleEndDateConfirmationForClosed.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.subProjectStatusChangeConfirmation = this.subProjectStatusChangeConfirmation.bind(this);
    }
    componentDidMount = () => {
        let editId = this.props.EditId;
        if (editId && editId > 0) {
            this.getProjectDetails(editId);
        } else {
            this.getProjectFormInputs();
        }
        let projectParent = this.props.Parent;
        if (projectParent && projectParent > 0) {
            let formValues = this.state.formValues;
            formValues['projectParent'] = projectParent;
            this.setState({ formValues : formValues }, function() {
            this.parentChange();
           });
        }
    }
    componentWillUnmount () {
        // Cleaning the mounted component just before leaving.
        formValues = {
            name : '',
            description : '',
            chargeCode : '',
            client : '',
            projectManager : '',
            startDate : '',
            endDate : '',
            projectStatus : '1',
            cor : '',
            minTimeInc : '',
            payType : '',
            offsite : false,
            approvalMethod : 'PM',
            lcr_required : false,
            notifyPm : true,
            reqLabourCategory : false,
            inheritResource : false,
            reqClientTimesheet : false,
            inheritReviewers : false,
            dateConfirmationError : '',
	        timesheet_location : false,
            prelim_review_flag : false,
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.Open !== this.state.open) {
            this.setState({ open: this.props.Open });
        }
    }
    onClose = () => {
        this.props.UpdateParentOnAddSuccess(false);
    }
    confirmationClose = () => {
        this.setState({
            validationPopupOpen : false,
            validationPopupMsg : '',
            popupType : '',
        });
    }
    getProjectFormInputs =() => {
        let apiEndpoint = 'api/project/add';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    let formValues = this.state.formValues;
                    if (response.data.default_start_date && !(this.props.Parent && this.props.Parent > 0)) {
                        formValues.startDate = response.data.default_start_date;
                    }
                    this.setState({
                        formInputs : response.data,
                        permission : response.data.permission, 
                        formValues : formValues,
                    }, function(){
                        this.clientChange();
                    });
                } else {
                    this.setState({
                        permission : false,
                        permissionErrMsg : response.data.perm_err_msg,
                    });
                } 
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formValues;
        if (evt.target.type && evt.target.type === 'checkbox') {
	    if(field == 'timesheet_location' && !evt.target.checked) {
		ff['lcr_required'] = false;
		ff[field] = evt.target.checked;
	    }
	    else {
                ff[field] = evt.target.checked;
	    }
        } else {
            ff[field] = evt.target.value;
        }
        this.setState({
            formValues: ff
        }, function () {
            if (field === 'client') {
                this.clientChange();
            }
            if (field === 'projectStatus') {
                this.statusChange();
            }
        });
    }
    handleDateChange = (date, name) => {
        let formValues = this.state.formValues;
        formValues[name] = date;
        this.setState({
            formValues: formValues,
        });
    };
    clientChange = () => {
        let selectedClient = this.state.formValues.client;
        let selectedProject = this.state.formValues.projectId; 
        let apiEndpoint = 'api/project/getprojects';
        if ((selectedClient || this.props.Parent)) {
            return httpServices.post(apiEndpoint, {client_id : selectedClient, project_id : selectedProject})
                .then(response => {
                    if (response.data) {
                        let formInputs = this.state.formInputs;
                        formInputs.parentProjects = response.data.projects;
                        let formValues = this.state.formValues;
                        let parentProject = this.state.formValues.projectParent;
                        let showInheritResources = this.state.showInheritResources;
                        let showInheritReviewers = this.state.showInheritReviewers;
                        if (this.props.EditId) {
                            if(parentProject === 0 || parentProject === null){
                                showInheritResources = false;
                                showInheritReviewers = false; 
                                formValues.inheritReviewers = false;
                                formValues.inheritResource = false;
                            }
                            else{
                                showInheritResources = true;
                                showInheritReviewers = true; 
                            }
                        }
                        this.setState({
                            formValues : formValues,
                            formInputs : formInputs,
                            showInheritResources : showInheritResources,
                            showInheritReviewers : showInheritReviewers
                        });
                    } 
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let project = 0;
        let formValues = this.state.formValues;
        let showInheritResources = true;
        if (sNodes.length < 1) {
            project = 0;
            formValues['inheritResource'] = false;
            showInheritResources = false;
        }
        else if (cNode.project_id) {
            project = cNode.project_id;
        }
        formValues['projectParent'] = project;
        this.setState({ formValues : formValues, showInheritResources : showInheritResources }, function() {
            if (!(this.props.EditId && this.props.EditId > 0)) {
             this.parentChange();
            }
        });
    }
    parentChange = () => {
        let parentProject = this.state.formValues.projectParent;
        let apiEndpoint = 'api/project/getprojectById';
        return httpServices.post(apiEndpoint, {project_id : parentProject})
            .then(response => {
                if (response.data) {
                    let formValues = this.state.formValues;
                    formValues.projectManager = response.data.project.project_manager;
                    formValues.payType = response.data.project.project_paytype;
                    formValues.startDate = response.data.project.project_sdate;
                    formValues.endDate = response.data.project.project_edate;
                    if (this.props.Parent && this.props.Parent > 0) {
                        formValues.client = response.data.project.project_client ? response.data.project.project_client : 0;
                        formValues.reqLabourCategory = (typeof response.data.project.labor_category !== 'undefined' && response.data.project.labor_category) ? parseInt(response.data.project.labor_category) : 0;
                        formValues.offsite = (typeof response.data.project.offsite !== 'undefined' && response.data.project.offsite) ? parseInt(response.data.project.offsite) : false;
                        formValues.chargeCode = response.data.project.project_code ? response.data.project.project_code : '';
                    }
                    let showInheritResources = true;
                    let showInheritReviewers = false;
                    if(parseInt(parentProject) !== 0){
                        showInheritResources = true;
                        showInheritReviewers = true;
                    }
                    else{
                        showInheritResources = false;
                        showInheritReviewers = false;
                        formValues.inheritReviewers = false;
                        formValues['inheritResource'] = false;
                        formValues.inheritResource = false;
                    }
                    this.setState({
                        formValues : formValues,
                        parentSdate : response.data.project.project_sdate,
                        parentEdate : response.data.project.project_edate,
                        showInheritResources : showInheritResources,
                        showInheritReviewers : showInheritReviewers,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    save = () => {
        let valid = this.validateFormData();
        if (valid) {
            if (this.props.EditId) {
                this.editProject();
            } else {
                let projectSdate = this.state.formValues.startDate;
                let projectEdate = this.state.formValues.endDate;
                let parentProjId = this.state.formValues.projectParent ? this.state.formValues.projectParent : 0;
                this.setState({ buttonLoader : true});
                if (parentProjId !== 0) {
                    // Date validation
                    let apiEndpoint = 'api/project/validateprojectdate';
                    return httpServices.post(apiEndpoint, {project_id : parentProjId, project_sdate : projectSdate, project_edate : projectEdate})
                        .then(response => {
                            if (response.data) {
                                if (response.data.flag) {
                                    this.createProject();
                                } else {
                                    let projectParent = this.props.Parent;
                                    if (projectParent && projectParent > 0) {
                                        this.setState({
                                            validationPopupMsg : "The dates you have entered do not fall in the range between the start and end date of the parent project. Please confirm.",
                                            popupType : 'dialog_confirm_dates',
                                            validationPopupOpen : true,
                                            buttonLoader : false,
                                            //errors : errors,
                                        });
                                    }
                                    else {
                                        this.setState({
                                            buttonLoader : false
                                        });
                                    }
                                }
                            }
                        })
                        .catch(function (error) {
                            console.log("Error: " + error);
                        });
                }
                else {
                    this.createProject();
                }
            }
        }
    }
    // Common validation function for project add/edit
    validateFormData = () => {
        let errors = {};
        let formValues = this.state.formValues;
        let valid = true;
        if (!formValues.name || formValues.name === 'undefined' || formValues.name.trim() === '') {
            errors['name'] = 'Project Name cannot be empty';
            valid = false;
        }
        if (!formValues.startDate || formValues.startDate === 'undefined' || formValues.startDate.trim() === 'Invalid date') {
            errors['startDate'] = 'Start Date cannot be empty or invalid';
            valid = false;
        }
        if (!formValues.chargeCode || typeof formValues.chargeCode === 'undefined' || formValues.chargeCode.trim() === '' ) {
            errors['chargeCode'] = 'Project Code cannot be empty';
            valid = false;
        }
        if (!formValues.description || typeof formValues.description === 'undefined' || formValues.description.trim() === '' ) {
            errors['description'] = 'Project Description cannot be empty';
            valid = false;
        }
        if (!formValues.client || formValues.client === '' ) {
            errors['client'] = 'Please select Client';
            valid = false;
        }
        if (!formValues.projectManager || formValues.projectManager === '' ) {
            errors['projectManager'] = 'Please select Project Manager';
            valid = false;
        }
        if (!formValues.startDate || formValues.startDate === '' ) {
            errors['startDate'] = 'Start date cannot be empty';
            valid = false;
        }
        if (!formValues.projectStatus || formValues.projectStatus === '' ) {
            errors['projectStatus'] = 'Please select Status';
            valid = false;
        }
        if (formValues.name.length > 64)
        {
            errors['name'] = 'Project name cannot exceed 64 characters';
            valid = false;
        }
        this.setState({
            errors : errors,
        });
        return valid;
    }
    createProject = () => {
        this.setState({
            buttonLoader: true,
        });
        let apiEndpoint = 'api/project/add';
        let data = this.state.formValues;
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data && response.data.success) {
                    this.setState({
                        buttonLoader: false,
                    });
                    this.props.UpdateParentOnAddSuccess(true);
                } else {
                    this.setState({
                        errors: response.data.errors,
                        buttonLoader: false
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    getProjectDetails = (id) => {
        let apiEndpoint = 'api/project/edit';
        let data = {
            projectId : id,
            action : 'get',
        }
        return httpServices.post(apiEndpoint,data)
            .then(response => {
                if (response.data && response.data.permission && response.data.success) {
                    let formValues = {};
                    let showInheritReviewers = this.state.showInheritReviewers;
                    let showInheritResources = this.state.showInheritResources;
                    let prevInheritResource = false;
                    if (response.data.project) {
                        let project = response.data.project;
                        formValues.name = project.project_name ? project.project_name : '';
                        formValues.projectId = project.project_id ? project.project_id : '';
                        formValues.chargeCode = project.project_code ? project.project_code : '';
                        formValues.description = project.project_description ? project.project_description : '';
                        formValues.client = project.project_client ? project.project_client : 0;
                        formValues.projectManager = project.project_manager ? project.project_manager : 0;
                        formValues.cor = project.cor_id ? project.cor_id : 0;
                        formValues.projectParent = project.project_parent ? project.project_parent : 0;
                        formValues.startDate = project.project_sdate ? project.project_sdate : null;
                        formValues.endDate = project.project_edate ? project.project_edate : null;
                        formValues.prevEndDate = project.project_edate ? project.project_edate : null;
                        formValues.projectStatus = project.project_status ? project.project_status : 0;
                        formValues.prevStatus = project.project_status ? project.project_status : 0;
                        formValues.minTimeInc = (project.minimum_increment || project.minimum_increment === 0)  ? project.minimum_increment : '';
                        formValues.payType = project.project_paytype ? project.project_paytype : 0;
                        formValues.notifyPm = (typeof project.notify_pm !== 'undefined' && project.notify_pm) ? parseInt(project.notify_pm) : false;
                        formValues.offsite = (typeof project.offsite !== 'undefined' && project.offsite) ? parseInt(project.offsite) : false;
                        formValues.lcr_required = (typeof project.lcr_required !== 'undefined' && project.lcr_required) ? parseInt(project.lcr_required) : false;
                        formValues.timesheet_location = (typeof project.timesheet_location !== 'undefined' && project.timesheet_location) ? parseInt(project.timesheet_location) : 0;
			            formValues.prelim_review_flag = (typeof project.prelim_review_flag !== 'undefined' && project.prelim_review_flag) ? parseInt(project.prelim_review_flag) : 0;
                        formValues.approvalMethod = project.wlc_approver ? project.wlc_approver : 'PM';
                        formValues.reqLabourCategory = (typeof project.labor_category !== 'undefined' && project.labor_category) ? parseInt(project.labor_category) : 0;
                        formValues.inheritReviewers = (project.inherit_reviewers !== 'undefined' && project.inherit_reviewers) ? parseInt(project.inherit_reviewers) : 0;
                        formValues.inheritResource = (typeof project.inherit_resource !== 'undefined' && project.inherit_resource) ? parseInt(project.inherit_resource) : 0;
                        formValues.reqClientTimesheet = (project.client_timesheet !== 'undefined' && project.client_timesheet) ? parseInt(project.client_timesheet) : 0;
                        prevInheritResource = response.data.project.inherit_resource;
                        if (project.project_parent && typeof project.project_parent !== 'undefined' && parseInt(project.project_parent) > 0) {
                            showInheritReviewers = true;
                            showInheritResources = true;
                        } else {
                            showInheritReviewers = false;
                            showInheritResources = false;
                        }
                    }
                    let taskCount = 0;
                    if (response.data.task_count && typeof response.data.task_count !== 'undefined') {
                        taskCount = parseInt(response.data.task_count);
                    }
                    let formInputs = response.data;
                    formInputs.parentProjects = response.data.parent_projects;
                    this.setState({
                        formInputs : formInputs,
                        showSubProject : response.data.show_subprojects,
                        formValues : formValues,
                        prParent : response.data.project.project_parent,
                        showInheritReviewers : showInheritReviewers,
                        showInheritResources : showInheritResources,
                        subProjects : response.data.subprojects,
                        permission : true,
                        taskCount : taskCount,
                        showLoader: false,
                        prevInheritResource : prevInheritResource
                    })
                }
                else if (!response.data.permission) { // Handling permission failure
                    this.setState({
                        showLoader : false,
                        permission : false,
                        permissionErrMsg : response.data.perm_err_msg,
                    });
                } else if (!response.data.success) { // Handling parent project status validation failure
                    this.setState({
                        showLoader : false,
                        permissionErrMsg : response.data.err_msg,
                        permission :  false,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    // Function for handling project edit status change
    statusChange = () => {
        if (this.state.formValues.projectStatus === 3) {
            if (this.state.showSubProject) {
                //$("#sub_edate_validate").val('0'); // TODO
            }
            this.askEndDate();
        }
    }
    editProject = () => {
        this.editProjectValidations();
    }
    // Function containing edit specific validations
    editProjectValidations = () => {
        let subProjectCount = this.state.subProjects.length;
        let submit = true;
        let currEndDate = this.state.formValues.endDate;
        let prevEndDate = this.state.formValues.prevEndDate;
        let prevStatus = this.state.formValues.prevStatus;
        let currStatus = this.state.formValues.projectStatus;
        let projectStatuses = this.state.formInputs.pstatuses;
        let statusIndex = projectStatuses.findIndex(x => x.project_status_id === currStatus);
        let statusObj = projectStatuses[statusIndex];
        let statusName = '';
        if (statusObj) {
            statusName = statusObj.project_status_name;
        }
        if (currStatus === 3 && (typeof currEndDate === 'undefined' || currEndDate === null || currEndDate.length < 1)){
            this.askEndDate();
            return false;
        }
        if (subProjectCount > 0 && prevStatus !== currStatus && currEndDate !== prevEndDate && typeof this.state.formValues.change_subproject_status === 'undefined'){
            submit = false;
            let valMsg = 'Status for all sub-projects are also updated to '+ statusName;
            if (currStatus == '3') {
                valMsg = 'Status for all sub-projects and its tasks are also updated to '+ statusName;  
            }
            this.setState({
                popupType : 'sub_project_edate_confirmation',
                validationPopupOpen : true,
                validationPopupMsg : valMsg,
            });
        }
        else if (subProjectCount > 0 && prevStatus !== currStatus && typeof this.state.formValues.change_subproject_status === 'undefined'){
            submit = false;
            let valMsg = 'Status for all sub-projects are also updated to '+ statusName;
            if (currStatus == '3') {
                 valMsg = 'Status for all sub-projects and its tasks are also updated to '+ statusName;  
            }
            this.setState({
                popupType : 'sub_project_status_confirmation',
                validationPopupOpen : true,
                validationPopupMsg : valMsg,
            });
        }
        else if(currEndDate !== prevEndDate && this.state.taskCount){
            submit =false;
            this.setState({
                popupType : 'active_task_edate_confirmation',
                validationPopupOpen : true,
            });
        }
        if(submit){
            this.validateDates();
        }
    }
    showTaskEndDateConfirmation = () => {
        if (this.state.taskCount) {
            this.setState({
                popupType : 'active_task_edate_confirmation',
                validationPopupOpen : true,
            });
        } else {
            this.taskEndDateConfirmation(false);
        }
    }
    taskEndDateConfirmation = (status) => {
        let formValues = this.state.formValues;
        if (status) {
            formValues.task_date = 'yes';
        } else {
            formValues.task_date = 'No';
        }
        this.setState({
            formValues : formValues,
        }, function() {
            this.validateDates();
        });
    }
    subProjectStatusChangeConfirmation = (status) => {
        let formValues = this.state.formValues;
        if (status) {
            formValues.change_subproject_status = 'yes';
        } else {
            formValues.change_subproject_status = 'no';
        }
        let currEndDate = this.state.formValues.endDate;
        let prevEndDate = this.state.formValues.prevEndDate;
        this.setState({
            formValues: formValues,
        }, function () {
            if (currEndDate !== prevEndDate) {
                this.showTaskEndDateConfirmation();
            } else {
                this.validateDates();
            }
        });
    }
    // Project edit specific validate function
    validateDates = () => {
        let id = this.state.formValues.projectId;
        let projectSdate = this.state.formValues.startDate;
        let projectEdate = this.state.formValues.endDate;
        let projectId = this.state.formValues.projectParent;
        let data = {
            project_sdate : projectSdate, 
            project_edate : projectEdate, 
            project_id : projectId, 
            id:id
        };
        // Date validation
        let apiEndpoint = 'api/project/validateprojectdate';
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data) {
                    this.setState({
                        validationPopupOpen : false,
                    });
                    let data = response.data;
                    if (data.date_changed) {
                        if (data.flag && typeof data.sub_projects_count !== 'undefined' && parseInt(data.sub_projects_count) === 0){
                            this.updateProject();
                        }
                        else if (data.flag && typeof data.sub_projects_count !== 'undefined' && (parseInt(data.sub_projects_count) !== 0)) {
                            let msg = "Sub Project(s) are not in the span of the Project. Please confirm the dates.";
                            let errors = this.state.errors;
                            this.setState({
                                validationPopupMsg : msg,
                                popupType : 'dialog_confirm_dates',
                                validationPopupOpen : true,
                                errors : errors,
                            });
                        }
                        else if (!data.flag && typeof data.sub_projects_count !== 'undefined' && ( parseInt(data.sub_projects_count) !== 0)) {
                            let msg = "The dates you have entered do not fall in the range between the start and end date of the parent project. Also, Sub Project(s) are not in the span of the Project. Please confirm the dates.";
                            let errors = this.state.errors;
                            this.setState({
                                validationPopupMsg : msg,
                                popupType : 'dialog_confirm_dates',
                                validationPopupOpen : true,
                                errors : errors,
                            });
                        }
                        else if (!data.flag && typeof data.sub_projects_count !== 'undefined' && (parseInt(data.sub_projects_count) === 0)) {
                            let msg = "The dates you have entered do not fall in the range between the start and end date of the parent project. Please confirm.";
                            let errors = this.state.errors;
                            this.setState({
                                validationPopupMsg : msg,
                                popupType : 'dialog_confirm_dates',
                                validationPopupOpen : true,
                                errors : errors,
                            });
                        }
                    }
                    else {
                        this.updateProject();
                    }
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    updateProject = () => {
        if (this.state.formValues.inheritResource !== this.state.prevInheritResource) {
            let apiEndpoint = 'api/project/validateInheritance';
            let data = this.state.formValues;
            return httpServices.post(apiEndpoint, {project_id : this.state.formValues.projectId, inheritResource : this.state.formValues.inheritResource})
                .then(response => {
                    if (response.data) {
                        this.setState({
                            inheritanceArray : response.data.inheritanceArray,
                            validationPopupOpen : true,
                        });
                    } else {
                        this.setState({
                            buttonLoader : false,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
        else {
            this.saveProject();
        }        
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    saveProject = () => {
        this.setState({
            buttonLoader : true,
        });
        let apiEndpoint = 'api/project/edit';
        let data = Object.assign({}, this.state.formValues);
        data.action = 'update';
        if (this.state.subProjects && typeof this.state.subProjects !== 'undefined' && this.state.subProjects.length) {
            data.subprj_enddate = JSON.stringify(this.state.subProjects);
        }
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data && response.data.success) {
                    this.props.UpdateParentOnAddSuccess(true);
                }
                else if (response.data.errors) {
                    this.setState({
                        buttonLoader : false,
                        errors : response.data.errors
                    });
                } 
                else {
                    this.setState({
                        buttonLoader : false,
                        postValidationErr : response.data.err_msg,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    confirmDatesSubmit = () => {
        let confirmSDate = this.state.formValues.confirmStartDate;
        let confirmEDate = this.state.formValues.confirmEndDate;
        let edate = this.state.formValues.endDate;
        if ((typeof confirmSDate === 'undefined' || confirmSDate === '')) {
            let msg = 'Start date is required.';
            this.setState({
                dateConfirmationError : msg,
            });
        }
        else {
            let formValues = this.state.formValues;
            formValues.startDate = confirmSDate;
            formValues.endDate = confirmEDate;

            this.setState({
                validationPopupOpen : false,
                formValues : formValues,
            }, function () {
                 let projectParent = this.props.Parent;
                 if (projectParent && projectParent > 0) {
                     this.createProject();
                 }
                else
                {
                 this.updateProject();
                }
            });
        }
    }
    // Ask end date for all sub projects
    askEndDate = () => {
        this.setState({
            validationPopupOpen : true,
            popupType : 'end_date_confirmation_for_all_subprojects'
        }); 
    }
    handleEndDateConfirmationForClosed = (date, name) => {
        if (name) {
            let subProjects = this.state.subProjects;
            let index = subProjects.findIndex(x => x.project_id === parseInt(name));
            if (index !== -1) {
                let curObj = subProjects[index];
                curObj.project_edate = date;
                subProjects[index] = curObj;
            } 
            this.setState({
                subProjects : subProjects,
            });
        }         
    }
        
    render() {
        return (
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            <ProjectAddComponent
                FormInputs = {this.state.formInputs}
                HandleOnChange = {this.handleOnChange}
                FormValues = {this.state.formValues}
                HandleTreeChange = {this.handleTreeChange}
                ShowInheritResources = {this.state.showInheritResources}
                ShowInheritReviewers = {this.state.showInheritReviewers}
                Errors = {this.state.errors}
                Save = {this.save}
                Open = {this.state.open}
                OnClose = {this.onClose}
                Title = {this.props.Title}               
                StatusChange = {this.statusChange}
                ValidationPopupOpen = {this.state.validationPopupOpen}
                ValidationPopupMsg = {this.state.validationPopupMsg}
                PopupType = {this.state.popupType}
                ConfirmationClose = {this.confirmationClose}
                ShowTaskEndDateConfirmation = {this.showTaskEndDateConfirmation}
                ValidateDates = {this.validateDates}
                ConfirmDatesSubmit = {this.confirmDatesSubmit}
                SubProjects = {this.state.subProjects}
                TaskEndDateConfirmation = {this.taskEndDateConfirmation}
                ProjectParent = {this.props.Parent}
                Permission = {this.state.permission}
                PermissionErrMsg = {this.state.permissionErrMsg}
                PostValidationErr = {this.state.postValidationErr}
                HandleEndDateConfirmationForClosed = {this.handleEndDateConfirmationForClosed}
                DateConfirmationError = {this.state.dateConfirmationError}
                ButtonLoader={this.state.buttonLoader}
                handleDateChange = {this.handleDateChange}
                NameFormat = {this.state.nameFormat}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                SnackbarVarient = {this.state.snackbarVarient}
                SnackbarClose = {this.snackbarClose}
                SaveProject = {this.saveProject}
                SubProjectStatusChangeConfirmation = {this.subProjectStatusChangeConfirmation}
                InheritanceArray = {this.state.inheritanceArray}
            />
            </>
        )
    }
}
